import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  claimTrialSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "9.3vh 3.1vw",
    background: "#212121",
    [`@media screen and (max-width: 1600px)`]: {
      padding: "80px 60px",
    },
    [`@media screen and (max-width: 700px)`]: {
      padding: "60px 60px 40px",
    },
  },
  claimTrialTitle: {
    ...theme.typography.h1,
    color: "white",
    textAlign: "center",

    [`@media screen and (max-width: 700px)`]: {
      fontSize: "32px",
    },
  },
  claimTrialSeparator: {
    width: "6.8vw",
    border: "2px solid #fff",
    margin: "5.6vh 0",
    [`@media screen and (max-width: 700px)`]: {
      width: "20vw",
      margin: "4.6vh 0",
    },
  },
  claimTrialDescription: {
    ...theme.typography.h2,
    color: "white",
    textAlign: "center",

    [`@media screen and (max-width: 700px)`]: {
      fontSize: "24px",
    },
  },
  claimTrialButton: {
    ...theme.typography.button1,
    marginTop: "5.6vh",
    width: "32%",
    height: "5.6vh",
    color: "black",
    background: "#E8E9F3",
    border: "1px solid white",
    borderRadius: "12px",
    "&:hover": {
      border: "0",
      color: "white",
      background: "linear-gradient(90deg , #25497E, #6D7A8D)",
    },
    [`@media screen and (max-width: 1600px)`]: {
      height: "50px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      width: "40%",
    },
    [`@media screen and (max-width: 900px)`]: {
      width: "55%",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "16px",
      width: "auto",
      border: "0",
      color: "white",
      background: "linear-gradient(90deg , #25497E, #6D7A8D)",
      padding: "6px 20px",
    },
    [`@media screen and (max-width: 500px)`]: {
      width: "92%",
      padding: "0",
      fontSize: "14px",
    },
  },
}));

interface ClaimTrialSectionProps {}

export const ClaimTrialSection: React.FC<ClaimTrialSectionProps> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.claimTrialSection}>
      <h2 className={classes.claimTrialTitle}>Start with NMS today!</h2>
      <div className={classes.claimTrialSeparator}></div>
      <div className={classes.claimTrialDescription}>
        Get full access to the platform.
      </div>
      <Button
        className={classes.claimTrialButton}
        onClick={(e) => {
          e.preventDefault();
          window.open("https://calendly.com/nodemonitoring/30", "_blank");
        }}
      >
        Start Your 30-Day Free Trial
      </Button>
    </div>
  );
};
