import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { BlogSegment } from "./blog-segment/BlogSegment";
import { AnimateComponentOnScroll } from "../../animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "7.4vh 10vw",
    textAlign: "center",
    backgroundColor: "#212121",
    height: "100%",
    overflowX: "hidden",
    [`@media screen and (max-width: 1600px)`]: {
      padding: "8vh 10vw 9vh",
    },
    [`@media screen and (max-width: 1200px)`]: {
      padding: "8vh 10vw 2vh",
    },
    [`@media screen and (max-width: 900px)`]: {
      padding: "6vh 10vw 4vh",
    },
    [`@media screen and (max-width: 700px)`]: {
      padding: "4vh 10vw 5vh",
    },
  },
  description: {
    ...theme.typography.h2,
    width: "100%",
    fontWeight: "bold",
    color: "#fff",
    margin: "0 0 6vh",
    [`@media screen and (max-width: 1200px)`]: {
      margin: "0 0 4vh",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "24px",
    },
  },
  blogSegment: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "0",

    [`@media screen and (max-width: 1600px)`]: {
      gap: "2.1vw",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

interface BlogSectionProps {}

export const BlogSection: React.FC<BlogSectionProps> = () => {
  const { classes } = useStyles();
  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  const posts = [
    {
      thumbnail: "blog-image-ocr",
      title:
        "4 OCR Job Run Metrics Every Chainlink Node Operator Should Monitor",
      link: "https://blog.linkriver.io/monitor-job-run-metrics/",
      description:
        "A list to illustrate the importance of sophisticated Chainlink node monitoring and debugging",
      author: {
        image: "author-image-first",
        name: "LinkRiver",
        releaseDate: "24 October 2023",
        readTime: "6 min read",
      },
    },
    {
      thumbnail: "blog-image-routine",
      title: "8 Steps to Achieve a Seamless Monitoring Routine",
      link: "https://blog.linkriver.io/monitoring-routine/",
      description:
        "A step-by-step guide aimed at professional Chainlink node operators who want to optimize monitoring processes and efficiency",
      author: {
        image: "author-image-first",
        name: "LinkRiver",
        releaseDate: "3 October 2024",
        readTime: "7 min read",
      },
    },
    {
      thumbnail: "blog-image-launch",
      title:
        "Node Monitoring Service (NMS) Officially Launches at Chainlink SmartCon 2024",
      link: "https://blog.linkriver.io/node-monitoring-service/",
      description:
        "Announcing the official launch of a unique solution designed to dramatically simplify monitoring of Chainlink node infrastructure",
      author: {
        image: "author-image-first",
        name: "LinkRiver",
        releaseDate: "29 October 2024",
        readTime: "8 min read",
      },
    },
  ];

  const sortedPosts = [...posts].sort((a, b) =>
    isSmallScreen
      ? new Date(b.author.releaseDate).getTime() -
        new Date(a.author.releaseDate).getTime()
      : new Date(a.author.releaseDate).getTime() -
        new Date(b.author.releaseDate).getTime()
  );

  return (
    <div id="blog" className={classes.root}>
      <AnimateComponentOnScroll enterDirection="right">
        <Typography variant="h2" className={classes.description}>
          Learn more about the only Web3 Node Monitoring Service of its kind on
          the LinkRiver blog.
        </Typography>
      </AnimateComponentOnScroll>

      <Box className={classes.blogSegment}>
        {sortedPosts.map((post, index) => (
          <AnimateComponentOnScroll
            key={post.title}
            enterDirection="right"
            display="flex"
            delay={isSmallScreen ? "0s" : `${index * 0.5}s`}
            justifyContent={
              isSmallScreen
                ? "center"
                : index === 0
                ? "end"
                : index === 2
                ? "start"
                : "center"
            }
          >
            <BlogSegment
              thumbnail={post.thumbnail}
              title={post.title}
              link={post.link}
              description={post.description}
              author={post.author}
            />
          </AnimateComponentOnScroll>
        ))}
      </Box>
    </div>
  );
};
