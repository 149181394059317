import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LandingPage } from "./components/landing-page/LandingPage";
import { PrivacyPage } from "./components/privacy-page/PrivacyPage";
import { TermsPage } from "./components/terms-page/TermsPage";
import { SubProcessorsPage } from "./components/sub-processors/SubProcessors";
import { DpaPage } from "./components/dpa/DpaPage";

export const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/terms" element={<TermsPage />}></Route>
        <Route path="/privacy" element={<PrivacyPage />}></Route>
        <Route path="/dpa" element={<DpaPage />}></Route>
        <Route path="/sub-processors" element={<SubProcessorsPage />}></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default App;
