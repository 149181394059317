import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { BlogSegmentFooter } from "./blog-segment-footer/BlogSegmentFooter";
import { MBlogSegmentFooter } from "./blog-segment-footer/MBlogSegmentFooter";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "left",
    color: "#fff",
    textDecoration: "none",
    width: "18.8vw",
    height: "100%",
    [`@media screen and (max-width: 1600px)`]: {
      width: "23.1vw",
    },
    [`@media screen and (max-width: 1200px)`]: {
      width: "24.4vw",
    },
    [`@media screen and (max-width: 960px)`]: {
      width: "27.5vw",
    },
    [`@media screen and (max-width: 900px)`]: {
      width: "66.6vw",
    },
    [`@media screen and (max-width: 600px)`]: {
      width: "100%",
    },

    "&:hover": {
      "& > img:first-of-type": {
        filter: "brightness(80%)",
      },
      "& > p:first-of-type": {
        color: "#3278BE",
      },
    },
  },
  topContent: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    borderRadius: "15px",
    maxHeight: "29vh",

    [`@media screen and (max-width: 1600px)`]: {
      maxWidth: "100%",
    },
    [`@media screen and (max-width: 900px)`]: {
      maxHeight: "unset",
    },
  },
  title: {
    ...theme.typography.h3,
    fontWeight: 700,
    margin: "2.3vh 0 0.9vh",
    lineHeight: "1.3  !important",
    display: "-webkit-box",
    height: "10.5vh",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    textOverflow: "ellipsis",
    color: "#fff",

    [`@media screen and (max-width: 1600px)`]: {
      margin: "20px 0 5px",
      fontSize: "21px",
      height: "11vh",
    },
    [`@media screen and (max-width: 1300px)`]: {
      margin: "20px 0 5px",
      fontSize: "19px",
      height: "fit-content",
    },
    [`@media screen and (max-width: 960px)`]: {
      height: "fit-content",
      margin: "20px 0 10px",
    },
    [`@media screen and (max-width: 700px)`]: {
      margin: "20px 0 10px",
      fontSize: "18px",
      height: "fit-content",
    },
  },
  description: {
    ...theme.typography.subtitle,
    color: "#fff",
    marginBottom: "2.3vh",
    lineHeight: "1.3  !important",

    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "15px",
      marginBottom: "20px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
}));

export interface AuthorData {
  image: string;
  name: string;
  releaseDate: string;
  readTime: string;
}

interface BlogSegmentProps {
  thumbnail: string;
  title: string;
  link: string;
  description: string;
  author: AuthorData;
}

export const BlogSegment: React.FC<BlogSegmentProps> = ({
  thumbnail,
  title,
  link,
  description,
  author,
}) => {
  const { classes } = useStyles();

  return (
    <a className={classes.root} href={link} target="_blank" rel="noreferrer">
      <div className={classes.topContent}>
        <img
          className={classes.image}
          src={`../images/${thumbnail}.webp`}
          alt="thumbnail"
        />
        <MBlogSegmentFooter authorData={author} />
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </div>
      <BlogSegmentFooter authorData={author} />
    </a>
  );
};
