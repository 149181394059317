import { createTheme } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import type { ThemeOptions } from "@mui/material/";

const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightSemiBold = 600;

const palette: PaletteOptions = {
  primary: {
    light: "#6D7A8D",
    main: "#212121",
    contrastText: "#FFF",
  },
  button: {
    main: "#212121",
    mainText: "#FFF",
    light: "#FFFF",
    lightText: "#212121",
    blue: "#25497E",
    blueText: "#FFF",
  },
  shadow: "#6D7A8D1A",
  background1: "#FFF",
  background2: "#E8E9F3",
};

const mediaQuery1600 = `@media screen and (max-width: 1600px)`;
const mediaQuery2K = `@media screen and (min-width: 2200px)`;
const mediaQuery4K = `@media screen and (min-width: 3100px)`;

const typography: TypographyOptions = {
  fontFamily: '"Poppins", Roboto, Arial, sans-serif',
  h1: {
    fontSize: "34px",
    fontWeight: fontWeightSemiBold,
    [mediaQuery1600]: {
      fontSize: "30px",
    },
    [mediaQuery2K]: {
      fontSize: "45.22px",
    },
    [mediaQuery4K]: {
      fontSize: "68px",
    },
  } as any,
  h2: {
    fontSize: "28px",
    fontWeight: fontWeightSemiBold,
    [mediaQuery1600]: {
      fontSize: "24px",
    },
    [mediaQuery2K]: {
      fontSize: "37.24px",
    },
    [mediaQuery4K]: {
      fontSize: "56px",
    },
  } as any,
  h3: {
    fontSize: "23px",
    fontWeight: fontWeightSemiBold,
    [mediaQuery1600]: {
      fontSize: "18px",
    },
    [mediaQuery2K]: {
      fontSize: "30.59px",
    },
    [mediaQuery4K]: {
      fontSize: "46px",
    },
  } as any,
  button1: {
    fontSize: "20px",
    [mediaQuery1600]: {
      fontSize: "18px",
    },
    [mediaQuery2K]: {
      fontSize: "26.6px",
    },
    [mediaQuery4K]: {
      fontSize: "40px",
    },
  } as any,
  button2: {
    fontSize: "18px",
    [mediaQuery1600]: {
      fontSize: "14px",
    },
    [mediaQuery2K]: {
      fontSize: "24.1px",
    },
    [mediaQuery4K]: {
      fontSize: "36px",
    },
  } as any,
  subtitle: {
    fontSize: "16px",
    fontWeight: fontWeightMedium,
    [mediaQuery1600]: {
      fontSize: "11px",
    },
    [mediaQuery2K]: {
      fontSize: "21.33px",
    },
    [mediaQuery4K]: {
      fontSize: "32px",
    },
  } as any,
  body1: {
    fontSize: "14px",
    fontWeight: fontWeightRegular,
    [mediaQuery1600]: {
      fontSize: "22px",
    },
    [mediaQuery2K]: {
      fontSize: "18.62px",
    },
    [mediaQuery4K]: {
      fontSize: "28px",
    },
  } as any,
  caption1: {
    fontSize: "11px",
    fontWeight: fontWeightRegular,
    [mediaQuery1600]: {
      fontSize: "9px",
    },
    [mediaQuery2K]: {
      fontSize: "14.66px",
    },
    [mediaQuery4K]: {
      fontSize: "22px",
    },
  } as any,
  caption2: {
    fontSize: "10px",
    fontWeight: fontWeightRegular,
    [mediaQuery1600]: {
      fontSize: "8px",
    },
    [mediaQuery2K]: {
      fontSize: "13.33px",
    },
    [mediaQuery4K]: {
      fontSize: "20px",
    },
  } as any,
  callOut: {
    fontSize: "9px",
    fontWeight: fontWeightRegular,
    [mediaQuery1600]: {
      fontSize: "8px",
    },
    [mediaQuery2K]: {
      fontSize: "11.99px",
    },
    [mediaQuery4K]: {
      fontSize: "18px",
    },
  } as any,
  code: {
    fontSize: "14px",
    fontWeight: fontWeightRegular,
    [mediaQuery1600]: {
      fontSize: "10.5px",
    },
    [mediaQuery2K]: {
      fontSize: "18.62px",
    },
    [mediaQuery4K]: {
      fontSize: "28px",
    },
  } as any,
};

const themeConfig: ThemeOptions = {
  palette,
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          transition: "background-color 0s",
          "&:hover": {
            background: "linear-gradient(90deg , #25497E, #212121)",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: typography.caption1?.fontSize,
          background: palette.background2,
          borderRadius: "23px",
          padding: "20px",
          marginLeft: "-20px",
          color: "black",
        },
        arrow: {
          marginLeft: "10px",
          color: palette.background2,
        },
      },
    },
  },
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    callOut: React.CSSProperties;
    subtitle: React.CSSProperties;
    body1: React.CSSProperties;
    code: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
    callOut?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    body1?: React.CSSProperties;
    code?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    button1: true;
    button2: true;
    caption1: true;
    caption2: true;
    callOut: true;
    subtitle: true;
    body1: true;
    code: true;
  }
}

interface Button {
  main: string;
  mainText: string;
  light: string;
  lightText: string;
  blue: string;
  blueText: string;
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    button: Button;
    shadow: string;
    background1: string;
    background2: string;
  }

  interface Palette {
    button: Button;
    shadow: string;
    background1: string;
    background2: string;
  }
}

export const lrTheme = createTheme(themeConfig);
