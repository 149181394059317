import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography, Box } from "@mui/material";
import { AuthorData } from "../BlogSegment";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      marginBottom: "40px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  author: {
    display: "flex",
    flexDirection: "column",
  },
  authorImage: {
    height: "3.9vh",
    borderRadius: "50px",
    marginRight: "0.8vw",
    [`@media screen and (max-width: 1300px)`]: {
      height: "3vh",
    },
  },
  name: {
    ...theme.typography.button2,
    fontWeight: 500,
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "18px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      fontSize: "16px",
    },
    [`@media screen and (max-width: 900px)`]: {
      fontSize: "14px",
    },
  },
  date: {
    ...theme.typography.subtitle,
    fontWeight: 400,
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "16px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      fontSize: "14px",
    },
  },
}));

interface BlogSegmentFooterProps {
  authorData: AuthorData;
}

export const BlogSegmentFooter: React.FC<BlogSegmentFooterProps> = ({
  authorData,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <img
        className={classes.authorImage}
        src={`../images/${authorData.image}.png`}
        alt="author"
      ></img>
      <Box className={classes.author}>
        <Typography className={classes.name}>{authorData.name}</Typography>
        <Typography
          className={classes.date}
        >{`${authorData.releaseDate}\u00A0\u00A0•\u00A0\u00A0${authorData.readTime}`}</Typography>
      </Box>
    </Box>
  );
};
