import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    lineHeight: "1.6",
    padding: "0 70px 30px",

    [`@media screen and (max-width: 1200px)`]: {
      padding: "0 50px 30px",
    },
    [`@media screen and (max-width: 700px)`]: {
      padding: "0 20px 30px",
    },
  },
  header: {
    padding: "50px 0 30px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "1.8em",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "1.5em",
    },
  },
  subHeader: {
    paddingTop: "20px",
    fontSize: "21px",
    fontWeight: "700",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "19px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "17px",
    },
  },
  text: {
    fontSize: "16px",
    marginBottom: "20px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
      marginLeft: "0",
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  tableContainer: {
    overflow: "auto",
  },
  table: {
    marginTop: "20px",
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid black",
  },
  th: {
    width: "15%",
    border: "1px solid black",
    textAlign: "left",
    padding: "10px",
    fontWeight: "700",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  td: {
    width: "85%",
    padding: "10px",
    textAlign: "left",
    border: "1px solid black",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  tdNoWidth: {
    padding: "10px",
    textAlign: "left",
    border: "1px solid black",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  list: {
    margin: "10px",
    display: "flex",
  },
  listTitle: {
    display: "flex",
    marginTop: "30px",
  },
  listText: {
    marginBottom: "0",

    [`@media screen and (max-width: 1200px)`]: {
      marginLeft: "0",
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      marginLeft: "0",
      fontSize: "13px",
    },
  },
  subtitle: {
    paddingTop: "20px",
    fontSize: "21px",
    fontWeight: "700",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "19px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "17px",
    },
  },
  textTitle: {
    fontSize: "16px",
    margin: "50px 0 20px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  listNumber: {
    minWidth: "40px",
    fontSize: "16px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  subtitleFirst: {
    minWidth: "40px",
    paddingTop: "20px",
    fontSize: "21px",
    fontWeight: "700",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "19px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "17px",
    },
  },
  definitionTitle: {
    minWidth: "200px",
    fontSize: "16px",
    fontWeight: "700",
    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
      minWidth: "150px",
    },
  },
  description: {
    paddingBottom: "60px",
  },
}));

interface DpaPageProps {}

export const DpaPage: React.FC<DpaPageProps> = () => {
  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className={classes.contentContainer}>
        <h1 className={classes.header}>DATA PROCESSING AGREEMENT</h1>
        <p className={classes.description}>
          This DPA is entered into between the Controller and the Processor and
          is incorporated into and governed by the terms of the Agreement.
        </p>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>1.</dt>
          <dd className={classes.subtitle}>Definitions</dd>
        </dl>
        <p className={classes.text}>
          Any capitalised term not defined in this DPA shall have the meaning
          given to it in the Agreement.
        </p>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Affiliate"</dt>
          <dd className={classes.text}>
            means any entity that directly or indirectly controls, is controlled
            by, or is under common control of a party. “Control”, for purposes
            of this definition, means direct or indirect ownership or control of
            more than 50% of the voting interests of a party;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Agreement"</dt>
          <dd className={classes.text}>
            means the agreement between the Controller and the Processor for the
            provision of the Services;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Controller"</dt>
          <dd className={classes.text}>means the Customer;</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Data Protection Law"</dt>
          <dd className={classes.text}>
            means all laws and regulations, including laws and regulations of
            the European Union, the European Economic Area, their member states
            and the United Kingdom, any amendments, replacements or renewals
            thereof, applicable to the processing of Personal Data, including
            where applicable the Federal Law No. 2 of 2019 on the Protection of
            Personal Data (the "UAE Data Protection Law"), Data Protection,
            Privacy and Electronic Communications (Amendments etc.) (EU Exit)
            Regulations 2020, the EU GDPR, the UK GDPR, the UK Data Protection
            Act 2018, the FADP, US State Privacy Laws and any applicable
            national implementing laws, regulations and secondary legislation
            relating to the processing of the Personal Data and the privacy of
            electronic communications, as amended, replaced or updated from time
            to time, including the Privacy and Electronic Communications
            Directive (2002/58/EC) and the Privacy and Electronic Communications
            (EC Directive) Regulations 2003 (SI 2003/2426);
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Data Subject"</dt>
          <dd className={classes.text}>
            shall have the same meaning as in Data Protection Law or means a
            “Consumer” as that term is defined in US State Privacy Laws;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"DPA"</dt>
          <dd className={classes.text}>
            means this data processing agreement together with Exhibits A, B and
            C;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"EEA"</dt>
          <dd className={classes.text}>means the European Economic Area;</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"EU GDPR"</dt>
          <dd className={classes.text}>
            means Regulation (EU) 2016/679 of the European Parliament and of the
            Council of 27 April 2016 on the protection of natural persons with
            regard to the processing of personal data and on the free movement
            of such data, (General Data Protection Regulation);
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"FADP"</dt>
          <dd className={classes.text}>
            means the Swiss Federal Act on Data Protection of the 1st of
            September 2023, and as amended from time to time;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Personal Data"</dt>
          <dd className={classes.text}>
            shall have the same meaning as in Data Protection Law;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Processor"</dt>
          <dd className={classes.text}>
            means the Company, including as applicable any “Service Provider” as
            that term is defined in US State Privacy Laws;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Restricted Transfer"</dt>
          <dd className={classes.text}>
            means:<br></br>
            (i) where the EU GDPR applies, a transfer of Personal Data via the
            Services from the EEA either directly or via onward transfer, to any
            country or recipient outside of the EEA not subject to an adequacy
            determination by the European Commission; and<br></br>
            (ii) where the UK GDPR applies, a transfer of Personal Data via the
            Services from the United Kingdom either directly or via onward
            transfer, to any country or recipient outside of the UK not based on
            adequacy regulations pursuant to Section 17A of the United Kingdom
            Data Protection Act 2018; and<br></br>
            (iii) a transfer of Personal Data via the Services from Switzerland
            either directly or via onward transfer, to any country or recipient
            outside of the EEA and/or Switzerland not subject to an adequacy
            determination by the European Commission;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Services"</dt>
          <dd className={classes.text}>
            means all services and software applications and solutions provided
            to the Controller by the Processor under and as described in the
            Agreement;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"SCCs"</dt>
          <dd className={classes.text}>
            means:<br></br>
            (i) where the EU GDPR applies, the standard contractual clauses
            annexed to the European Commission's Implementing Decision 2021/914
            of 4 June 2021 on standard contractual clauses for the transfer of
            personal data to third countries published at https://eur-
            lex.europa.eu/legal-content/EN/TXT/HTML/?
            uri=CELEX:32021D0914&from=EN, (“<strong>EU SCCs</strong>”); and
            <br></br>
            (ii) where the UK GDPR applies standard data protection clauses
            adopted pursuant to Article 46(2)(c) of the UK GDPR as set out in
            Exhibit C of this DPA, (“<strong>UK SCCs</strong>”); and<br></br>
            (iii) where Personal Data is transferred from Switzerland to outside
            of Switzerland or the EEA, the EU SCCs as amended in accordance with
            guidance from the Swiss Data Protection Authority; (“
            <strong>Swiss SCCs</strong>”);
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Sub-processor"</dt>
          <dd className={classes.text}>
            means any third party (including the Processor’s Affiliates) engaged
            directly or indirectly by the Processor to process Personal Data
            under this DPA in the provision of the Services to the Controller;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"Supervisory Authority"</dt>
          <dd className={classes.text}>
            means a governmental or government chartered regulatory body having
            binding legal authority over a party;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"UK GDPR"</dt>
          <dd className={classes.text}>
            means the EU GDPR as it forms part of the law of England and Wales,
            Scotland and Northern Ireland by virtue of section 3 of the European
            Union (Withdrawal) Act 2018;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.definitionTitle}>"US State Privacy Laws"</dt>
          <dd className={classes.text}>
            means the following US state data protection or privacy laws and
            regulations applicable to the party’s Processing of Personal Data:
            California Consumer Privacy Act (<strong>CCPA</strong>) as amended
            by the California Privacy Rights Act (<strong>CPRA</strong>),
            Virginia Consumer Data Protection Act (<strong>VCDPA</strong>),
            Colorado Privacy Act (<strong>CPA</strong>), Connecticut Data
            Privacy Act (<strong>CTDPA</strong>
            ), and Utah Consumer Privacy Act (<strong>UCPA</strong>) and the
            Connecticut Data Privacy Act (<strong>CTDPA</strong>) in each case
            as may be amended or superseded from time to time.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>2.</dt>
          <dd className={classes.subtitle}>Purpose</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>2.1</dt>
          <dd className={classes.listText}>
            The Processor has agreed to provide the Services to the Controller
            in accordance with the terms of the Agreement. In providing the
            Services, the Processor shall process Customer Data on behalf of the
            Controller. Customer Data may include Personal Data. The Processor
            will process and protect such Personal Data in accordance with the
            terms of this DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>3.</dt>
          <dd className={classes.subtitle}>Scope</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>3.1</dt>
          <dd className={classes.listText}>
            In providing the Services to the Controller pursuant to the terms of
            the Agreement, the Processor shall process Personal Data only to the
            extent necessary to provide the Services in accordance with the
            terms of the Agreement, this DPA and the Controller’s instructions
            documented in the Agreement and this DPA, as updated from time to
            time.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>3.2</dt>
          <dd className={classes.listText}>
            The Controller and Processor shall take steps to ensure that any
            natural person acting under the authority of the Controller or the
            Processor who has access to Personal Data does not process them
            except on the instructions from the Controller unless required to do
            so by any Data Protection Law.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>4.</dt>
          <dd className={classes.subtitle}>Processor’s obligations</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.1</dt>
          <dd className={classes.listText}>
            The Processor may collect, process or use Personal Data only within
            the scope of this DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.2</dt>
          <dd className={classes.listText}>
            The Processor confirms that it shall process Personal Data on behalf
            of the Controller in accordance with the documented instructions of
            the Controller.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.3</dt>
          <dd className={classes.listText}>
            The Processor shall promptly inform the Controller, if in the
            Processor’s opinion, any of the instructions regarding the
            processing of Personal Data provided by the Controller, breach Data
            Protection Law.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.4</dt>
          <dd className={classes.listText}>
            The Processor shall ensure that all employees, agents, officers and
            contractors involved in the handling of Personal Data: (i) are aware
            of the confidential nature of the Personal Data and are
            contractually bound to keep the Personal Data confidential; (ii)
            have received appropriate training on their responsibilities as a
            data processor; and (iii) are bound by the terms of this DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.5</dt>
          <dd className={classes.listText}>
            The Processor shall implement appropriate technical and
            organisational measures to protect Personal Data, taking into
            account: (i) the state of the art; (ii) the costs of implementation;
            (iii) the nature, scope, context and purposes of processing; and
            (iv) the risk of varying likelihood and severity for the rights and
            freedoms of natural persons.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.6</dt>
          <dd className={classes.listText}>
            The Processor shall implement appropriate technical and
            organisational measures to ensure a level of security appropriate to
            the risk, including inter alia as appropriate: (i) the
            pseudonymisation and encryption of Personal Data; (ii) the ability
            to ensure the on-going confidentiality, integrity, availability and
            resilience of processing systems and services; (iii) the ability to
            restore the availability and access to Personal Data in a timely
            manner in the event of a physical or technical incident; (iv) a
            process for regularly testing, assessing and evaluating the
            effectiveness of technical and organisational measures for ensuring
            the security of the processing. In accessing the appropriate level
            of security, account shall be taken in particular of the risks that
            are presented by processing, in particular from accidental or
            unlawful destruction, loss, alteration, unauthorised disclosure of,
            or access to Personal Data transmitted, stored or otherwise
            processed.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.7</dt>
          <dd className={classes.listText}>
            The technical and organisational measures detailed in Exhibit B
            shall at all times be adhered to as a minimum security standard. The
            Controller accepts and agrees that the technical and organisational
            measures are subject to development and review and that the
            Processor may use alternative suitable measures to those detailed in
            the attachments to this DPA, provided such measures are at least
            equivalent to the technical and organisational measures set out in
            Exhibit B and appropriate pursuant to the Processor’s obligations in
            clauses 4.5 and 4.6 above.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.8</dt>
          <dd className={classes.listText}>
            The Controller acknowledges and agrees that, in the course of
            providing the Services to the Controller, it may be necessary for
            the Processor to access the Personal Data to respond to any
            technical problems or Controller queries and to ensure the proper
            working of the Services. All such access by the Processor will be
            limited to those purposes.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.9</dt>
          <dd className={classes.listText}>
            Taking into account the nature of the processing and the information
            available to the Processor, the Processor shall assist the
            Controller by having in place appropriate technical and
            organisational measures, insofar as this is possible, for the
            fulfilment of the Controller's obligation to respond to requests for
            exercising the Data Subject's rights and the Controller’s compliance
            with the Controller’s data protection obligations in respect of the
            processing of Personal Data.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.10</dt>
          <dd className={classes.listText}>
            The Processor may not: (i) sell Personal Data; (ii) retain, use, or
            disclose Personal Data for commercial purposes other than providing
            the Services under the terms of the Agreement; or (iii) retain, use,
            or disclose Personal Data outside of the terms of the Agreement.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>5.</dt>
          <dd className={classes.subtitle}>Controller’s obligations</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>5.1</dt>
          <dd className={classes.listText}>
            The Controller represents and warrants that: (i) it shall comply
            with this DPA and its obligations under Data Protection Law; (ii) it
            has obtained any, and all, permissions and authorisations necessary
            to permit the Processor, its Affiliates and Sub-processors, to
            execute their rights or perform their obligations under this DPA;
            and (iii) all Affiliates of the Controller who use the Services
            shall comply with the obligations of the Controller set out in this
            DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>5.2</dt>
          <dd className={classes.listText}>
            The Controller shall implement appropriate technical and
            organisational measures to protect Personal Data, taking into
            account: (i) the state of the art; (ii) the costs of implementation;
            (iii)the nature, scope, context and purposes of processing; and (iv)
            the risk of varying likelihood and severity for the rights and
            freedoms of natural persons.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>5.3</dt>
          <dd className={classes.listText}>
            The Controller shall implement appropriate technical and
            organisational measures to ensure a level of security appropriate to
            the risk, including inter alia as appropriate: (i) the
            pseudonymisation and encryption of Personal Data; (ii) the ability
            to ensure the on-going confidentiality, integrity, availability and
            resilience of processing systems and services; (iii) the ability to
            restore the availability and access to Personal Data in a timely
            manner in the event of a physical or technical incident; (iv) a
            process for regularly testing, assessing and evaluating the
            effectiveness of technical and organisational measures for ensuring
            the security of the processing. In accessing the appropriate level
            of security account shall be taken in particular of the risks that
            are presented by processing, in particular from accidental or
            unlawful destruction, loss, alteration, unauthorised disclosure of,
            or access to Personal Data transmitted, stored or otherwise
            processed.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>5.4</dt>
          <dd className={classes.listText}>
            The Controller acknowledges and agrees that some instructions from
            the Controller including the Processor assisting with audits,
            inspections, DPIAs or providing any assistance under this DPA, may
            result in additional fees. In such case the Processor shall notify
            the Controller of its fees for providing such assistance in advance
            and shall be entitled to charge the Controller for its reasonable
            costs and expenses in providing such assistance, unless agreed
            otherwise in writing.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>6.</dt>
          <dd className={classes.subtitle}>Sub-processors</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>6.1</dt>
          <dd className={classes.listText}>
            The Controller acknowledges and agrees that the Processor may engage
            Sub-processors in connection with the provision of the Services.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>6.2</dt>
          <dd className={classes.listText}>
            All Sub-processors who process Personal Data in the provision of the
            Services to the Controller shall comply with the obligations of the
            Processor set out in this DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>6.3</dt>
          <dd className={classes.listText}>
            The Controller authorises the Processor to use the Sub-processors
            included in the list of Sub- processors published at:{" "}
            <a href="https://nodemonitoring.io/sub-processors">
              https://nodemonitoring.io/sub-processors
            </a>{" "}
            to process the Personal Data. During the term of this DPA, the
            Processor shall provide the Controller with 30 days prior
            notification, via email, of any changes to the list of
            Sub-processors before authorising any new or replacement
            Sub-processor to process Personal Data in connection with provision
            of the Services.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>6.4</dt>
          <dd className={classes.listText}>
            The Controller may object to the use of a new or replacement
            Sub-processor, by notifying the Processor promptly in writing within
            fifteen (15) days after receipt of the Processor’s notice. If the
            Controller objects to a new or replacement Sub-processor, the
            Controller may terminate the Agreement with respect to those
            Services which cannot be provided by the Processor without the use
            of the new or replacement Sub-processor. The Processor will refund
            the Controller any prepaid fees covering the remainder of the term
            of the Agreement following the effective date of termination with
            respect to such terminated Services
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>6.5</dt>
          <dd className={classes.listText}>
            All Sub-processors who process Personal Data shall comply with the
            obligations of the Processor set out in this DPA. The Processor
            shall prior to the relevant Sub-processor carrying out any
            processing activities in respect of the Personal Data: (i) appoint
            each Sub-processor under a written contract containing materially
            the same obligations to those of the Processor in this DPA
            enforceable by the Processor; and (ii) ensure each such
            Sub-processor complies with all such obligations.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>6.6</dt>
          <dd className={classes.listText}>
            The Controller agrees that the Processor and its Sub-processors may
            make Restricted Transfers of Personal Data for the purpose of
            providing the Services to the Controller in accordance with the
            Agreement. The Processor confirms that such Sub-processors: (i) are
            located in a third country or territory recognised by the EU
            Commission or a Supervisory Authority, as applicable, to have an
            adequate level of protection; or (ii) have entered into the
            applicable SCCs with the Processor; or (iii) have other legally
            recognised appropriate safeguards in place.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>7.</dt>
          <dd className={classes.subtitle}>Restricted transfers</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>7.1</dt>
          <dd className={classes.listText}>
            The parties agree that, when a transfer of Personal Data occurs
            between the Controller and the Processor or from the Processor to a
            Sub-processor which is a Restricted Transfer, it shall be subject to
            the applicable SCCs.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>7.2</dt>
          <dd className={classes.listText}>
            The parties agree that the EU SCCs shall apply to Restricted
            Transfers from the EEA. The EU SCCs shall be deemed entered into
            (and incorporated into this DPA by reference) and completed as
            follows:
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(i)</dt>
          <dd className={classes.listText}>
            Module Two (Controller to Processor) shall apply where the Customer
            is a Controller of Personal Data and the Company is processing
            Personal Data;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(ii)</dt>
          <dd className={classes.listText}>
            Module Three (Processor to Processor) shall apply where the Company
            is a Processor of Personal Data and the Company uses a Sub-processor
            to process the Personal Data;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(iii)</dt>
          <dd className={classes.listText}>
            Module Four (Processor to Controller) shall apply where the Company
            is processing Personal Data and the Customer is not subject to the
            EU GDPR or UK GDPR;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(iv)</dt>
          <dd className={classes.listText}>
            In Clause 7 of the EU SCCs, the optional docking clause shall not
            apply;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(v)</dt>
          <dd className={classes.listText}>
            In Clause 9 of the EU SCCs, Option 2 applies, and the time period
            for giving notice of Sub- processor changes shall be as set out in
            clause 6.3 of this DPA;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(vi)</dt>
          <dd className={classes.listText}>
            In Clause 11 of the EU SCCs, the optional language shall not apply;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(vii)</dt>
          <dd className={classes.listText}>
            In Clause 17 of the EU SCCs, Option 1 applies and the EU SCCs shall
            be governed by Irish law;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(viii)</dt>
          <dd className={classes.listText}>
            In Clause 18(b) of the EU SCCs, disputes shall be resolved by the
            courts of Ireland;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(ix)</dt>
          <dd className={classes.listText}>
            Annex I of the EU SCCs shall be deemed completed with the
            information set out in Exhibit A of this DPA;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(x)</dt>
          <dd className={classes.listText}>
            Annex II of the EU SCCs shall be deemed completed with the
            information set out in Exhibit B of this DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>7.3</dt>
          <dd className={classes.listText}>
            The parties agree that the EU SCCs as amended in clause 7.2 above,
            shall be adjusted as set out below where the FADP applies to any
            Restricted Transfer:
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(i)</dt>
          <dd className={classes.listText}>
            The Swiss Federal Data Protection and Information Commissioner
            (“FDPIC”) shall be the sole Supervisory Authority for Restricted
            Transfers exclusively subject to the FADP;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(ii)</dt>
          <dd className={classes.listText}>
            Restricted Transfers subject to both the FADP and the EU GDPR, shall
            be dealt with by the EU Supervisory Authority named in Exhibit A of
            this DPA;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(iii)</dt>
          <dd className={classes.listText}>
            The term ’member state’ must not be interpreted in such a way as to
            exclude Data Subjects in Switzerland from the possibility of suing
            for their rights in their place of habitual residence (Switzerland)
            in accordance with Clause 18(c) of the EU SCCs;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(iv)</dt>
          <dd className={classes.listText}>
            Where Restricted Transfers are exclusively subject to the FADP, all
            references to the GDPR in the EU SCCs are to be understood to be
            references to the FADP;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>(v)</dt>
          <dd className={classes.listText}>
            Where Restricted Transfers are subject to both the FADP and the EU
            GDPR, all references to the GDPR in the EU SCCs are to be understood
            to be references to the FADP insofar as the Restricted Transfers are
            subject to the FADP;
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>7.4</dt>
          <dd className={classes.listText}>
            The parties agree that the UK SCCs shall apply to Restricted
            Transfers from the UK and the UK SCCs shall be deemed entered into
            (and incorporated into this DPA by reference), as set out in Exhibit
            C of this DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>7.5</dt>
          <dd className={classes.listText}>
            In the event that any provision of this DPA contradicts directly or
            indirectly any SCCs, the provisions of the applicable SCCs shall
            prevail over the terms of the DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>8.</dt>
          <dd className={classes.subtitle}>Data subject access requests</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>8.1</dt>
          <dd className={classes.listText}>
            The Controller may require correction, deletion, blocking and/or
            making available the Personal Data during or after termination of
            the Agreement. The Controller acknowledges and agrees that the
            Processor will process the request to the extent it is lawful and
            will reasonably fulfil such request in accordance with its standard
            operational procedures to the extent possible.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>8.2</dt>
          <dd className={classes.listText}>
            In the event that the Processor receives a request from a Data
            Subject in relation to Personal Data, the Processor will refer the
            Data Subject to the Controller unless otherwise prohibited by law.
            The Controller shall reimburse the Processor for all costs incurred
            resulting from providing reasonable assistance in dealing with a
            Data Subject request. In the event that the Processor is legally
            required to respond to the Data Subject, the Controller will fully
            cooperate with the Processor as applicable.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>9.</dt>
          <dd className={classes.subtitle}>Audit</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>9.1</dt>
          <dd className={classes.listText}>
            The Processor shall make available to the Controller all information
            reasonably necessary to demonstrate compliance with its processing
            obligations and allow for and contribute to audits and inspections.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>9.2</dt>
          <dd className={classes.listText}>
            Any audit conducted under this DPA shall consist of examination of
            the most recent reports, certificates and/or extracts prepared by an
            independent auditor bound by confidentiality provisions similar to
            those set out in the Agreement. In the event that provision of the
            same is not deemed sufficient in the reasonable opinion of the
            Controller, the Controller may conduct a more extensive audit which
            shall be: at the Controller’s expense; limited in scope to matters
            specific to the Controller and agreed in advance; carried out during
            the Processor’s usual business hours and upon reasonable notice
            which shall be not less than 4 weeks unless an identifiable material
            issue has arisen; conducted in a way which does not interfere with
            the Processor’s day-to-day business.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>9.3</dt>
          <dd className={classes.listText}>
            This clause shall not modify or limit the rights of audit of the
            Controller, instead it is intended to clarify the procedures in
            respect of any audit undertaken pursuant thereto.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>10.</dt>
          <dd className={classes.subtitle}>Personal data breach</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>10.1</dt>
          <dd className={classes.listText}>
            The Processor shall notify the Controller without undue delay after
            becoming aware of (and in any event within 72 hours of discovering)
            any breach of security leading to the accidental or unlawful
            destruction, loss, alteration or unauthorised disclosure or access
            to any Personal Data (“<strong>Personal Data Breach</strong>”).
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>10.2</dt>
          <dd className={classes.listText}>
            In the event of a Personal Data Breach, the Processor shall take all
            commercially reasonable measures to secure the Personal Data, to
            limit the effects of any Personal Data Breach, and to assist the
            Controller in meeting the Controller’s obligations under applicable
            law.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>11.</dt>
          <dd className={classes.subtitle}>
            Compliance, cooperation and response
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>11.1</dt>
          <dd className={classes.listText}>
            The Processor will notify the Controller promptly of any request or
            complaint regarding the processing of Personal Data, which adversely
            impacts the Controller, unless such notification is not permitted
            under applicable law or a relevant court order.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>11.2</dt>
          <dd className={classes.listText}>
            The Processor may make copies of and/or retain Personal Data in
            compliance with any legal or regulatory requirement including, but
            not limited to, retention requirements.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>11.3</dt>
          <dd className={classes.listText}>
            The Processor shall reasonably assist the Controller in meeting the
            Controller’s obligation to carry out data protection impact
            assessments (DPIAs), taking into account the nature of the
            processing and the information available to the Processor.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>11.4</dt>
          <dd className={classes.listText}>
            Any audit conducted under this DPA shall consist of examination of
            the most recent reports, certificates and/or extracts prepared by an
            independent auditor bound by confidentiality provisions similar to
            those set out in the Agreement. In the event that provision of the
            same is not deemed sufficient in the reasonable opinion of the
            Controller, the Controller may conduct a more extensive audit which
            shall be: (i) at the Controller’s expense; (ii) limited in scope to
            matters specific to the Controller and agreed in advance; (iii)
            carried out during the Processor’s usual business hours and upon
            reasonable notice which shall be not less than 4 weeks unless an
            identifiable material issue has arisen; and (iv) conducted in a way
            which does not interfere with the Processor’s day-to-day business.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>11.5</dt>
          <dd className={classes.listText}>
            The Controller and the Processor and, where applicable, their
            representatives, shall cooperate, on request, with a Supervisory
            Authority in the performance of their respective obligations under
            this DPA and Data Protection Law.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>12.</dt>
          <dd className={classes.subtitle}>Liability</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>12.1</dt>
          <dd className={classes.listText}>
            The limitations on liability set out in the Agreement apply to all
            claims made pursuant to any breach of the terms of this DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>12.2</dt>
          <dd className={classes.listText}>
            The parties agree that the Processor shall be liable for any
            breaches of this DPA caused by the acts and omissions or negligence
            of its Sub-processors to the same extent the Processor would be
            liable if performing the services of each Sub-processor directly
            under the terms of the DPA, subject to any limitations on liability
            set out in the terms of the Agreement.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>12.3</dt>
          <dd className={classes.listText}>
            The parties agree that the Controller shall be liable for any
            breaches of this DPA caused by the acts and omissions or negligence
            of its Affiliates as if such acts, omissions or negligence had been
            committed by the Controller itself.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>12.4</dt>
          <dd className={classes.listText}>
            The Controller shall not be entitled to recover more than once in
            respect of the same loss.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>13.</dt>
          <dd className={classes.subtitle}>Term and termination</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>13.1</dt>
          <dd className={classes.listText}>
            The Processor shall only process Personal Data for the term of the
            DPA. The term of this DPA shall commence on the Effective Date of
            the Agreement and this DPA shall terminate automatically together
            with termination or expiry of the Agreement.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>14.</dt>
          <dd className={classes.subtitle}>
            Deletion and return of personal data
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>14.1</dt>
          <dd className={classes.listText}>
            The Processor shall at the choice of the Controller, upon receipt of
            a written request received within 30 days of the end of the
            provision of the Services, delete or return Personal Data to the
            Controller. The Processor shall in any event delete all copies of
            Personal Data in its systems within 1 year of the effective date of
            termination of the Agreement or deactivation of the Controller’s
            account unless applicable law or regulations require storage of the
            Personal Data after termination.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.subtitleFirst}>15.</dt>
          <dd className={classes.subtitle}>General</dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>15.1</dt>
          <dd className={classes.listText}>
            This DPA sets out the entire understanding of the parties with
            regards to the subject matter herein.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>15.2</dt>
          <dd className={classes.listText}>
            Should a provision of this DPA be invalid or become invalid then the
            legal effect of the other provisions shall be unaffected. A valid
            provision is deemed to have been agreed which comes closest to what
            the parties intended commercially and shall replace the invalid
            provision. The same shall apply to any omissions.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>15.3</dt>
          <dd className={classes.listText}>
            Subject to any provision of the SCCs to the contrary, this DPA shall
            be governed by the laws of England and Wales. The courts of England
            shall have exclusive jurisdiction for the settlement of all disputes
            arising under this DPA.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>15.4</dt>
          <dd className={classes.listText}>
            The parties agree that this DPA is incorporated into and governed by
            the terms of the Agreement.
          </dd>
        </dl>
        <p className={classes.textTitle}>
          <strong>
            <u>Exhibit A</u>
          </strong>
        </p>
        <p className={classes.text}>
          <strong>
            List of Parties, Description of Processing and Transfer of Personal
            Data, Competent Supervisory Authority
          </strong>
        </p>
        <dl className={classes.listTitle}>
          <dt className={classes.subtitleFirst}>A.</dt>
          <dd className={classes.subtitle}>LIST OF PARTIES</dd>
        </dl>
        <p className={classes.text}>
          <strong>The Exporter:</strong>
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <td colSpan={2} className={classes.td}>
                means the Customer.
              </td>
            </tr>
            <tr>
              <td className={classes.th}>Address:</td>
              <td className={classes.td}>
                As set out for the Customer in the Agreement.
              </td>
            </tr>
            <tr>
              <td className={classes.th}>
                Contact person’s name, position and contact details:
              </td>
              <td className={classes.td}>
                As provided by the Customer in its account and used for
                notification and invoicing purposes.
              </td>
            </tr>
            <tr>
              <td className={classes.th}>
                Activities relevant to the data transferred under the SCCs:
              </td>
              <td className={classes.td}>Use of the Services.</td>
            </tr>
            <tr>
              <td className={classes.th}>Signature and date:</td>
              <td className={classes.td}>
                By entering into the Agreement, the Exporter is deemed to have
                signed the SCCs incorporated into this DPA and including their
                Annexes, as of the Effective Date of the Agreement.
              </td>
            </tr>
            <tr>
              <td className={classes.th}>Role:</td>
              <td className={classes.td}>Controller.</td>
            </tr>
            <tr>
              <td className={classes.th}>
                Name of Representative (if applicable):
              </td>
              <td className={classes.td}>
                Any UK or EU representative named in the Exporter’s privacy
                policy.
              </td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          <strong>The Importer:</strong>
        </p>

        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <td colSpan={2} className={classes.td}>
                means Node Monitoring FZCO
              </td>
            </tr>
            <tr>
              <td className={classes.th}>Address:</td>
              <td className={classes.td}>
                Node Monitoring FZCO, Dubai Silicon Oasis, DDP, Building A1,
                Dubai, United Arab Emirates.
              </td>
            </tr>
            <tr>
              <td className={classes.th}>
                Contact person’s name, position and contact details:
              </td>
              <td className={classes.td}>
                Nick Trommler, Managing Director,{" "}
                <a href="mailto:nick@nodemonitoring.io">
                  nick@nodemonitoring.io
                </a>
              </td>
            </tr>
            <tr>
              <td className={classes.th}>
                Activities relevant to the data transferred under the SCCs:
              </td>
              <td className={classes.td}>
                The provision of cloud computing solutions to the Exporter under
                which the Importer processes Personal Data upon the instructions
                of the Exporter in accordance with the terms of the Agreement.
              </td>
            </tr>
            <tr>
              <td className={classes.th}>Signature and date:</td>
              <td className={classes.td}>
                By entering into the Agreement, the Importer is deemed to have
                signed the SCCs, incorporated into this DPA, including their
                Annexes, as of the Effective Date of the Agreement.
              </td>
            </tr>
            <tr>
              <td className={classes.th}>Role:</td>
              <td className={classes.td}>Processor.</td>
            </tr>
            <tr>
              <td className={classes.th}>
                Name of Representative (if applicable):
              </td>
              <td className={classes.td}>
                DataRep, The Cube, Monahan Road, Cork, T12 H1XY, Republic of
                Ireland
              </td>
            </tr>
          </table>
        </div>
        <dl className={classes.listTitle}>
          <dt className={classes.subtitleFirst}>B.</dt>
          <dd className={classes.subtitle}>
            DESCRIPTION OF PROCESSING AND TRANSFERS
          </dd>
        </dl>
        <table className={classes.table}>
          <tr>
            <td className={classes.th}>Categories of Data Subjects:</td>
            <td className={classes.td}>
              Employees, agents, advisors, consultants, freelancers of the
              Controller (who are natural persons).<br></br>
              <br></br>
              Users, Affiliates and other participants authorised by the
              Controller to access or use the Services in accordance with the
              terms of the Agreement.
            </td>
          </tr>
          <tr>
            <td className={classes.th}>Categories of Personal Data:</td>
            <td className={classes.td}>
              The Controller may submit Personal Data to the Services, the
              extent of which is determined and controlled by the Controller.
              The Personal Data includes but is not limited to:
              <ul>
                <li>
                  Personal details, first name, middle name and surname, email
                  addresses, telephone number and company name and address of
                  users of the Services.
                </li>
                <li>
                  Unique identifiers such as username, account number or
                  password.
                </li>
                <li>VAT number (optional)</li>
                <li>
                  Personal Data derived from a user’s use of the Services such
                  as records and business intelligence information.
                </li>
                <li>
                  Personal Data within email and messaging content which
                  identifies or may reasonably be used to identify individuals.
                </li>
                <li>
                  Meta data including sent, to, from, date, time, subject, which
                  may include Personal Data.
                </li>
                <li>Geolocation based upon IP address.</li>
                <li>Financial data required for invoicing.</li>
                <li>Subscription history.</li> <li>Login history.</li>
                <li>
                  Metrics and logs from services configured and monitored by
                  Customer.
                </li>
                <li>File attachments that may contain Personal Data.</li>
                <li>Survey, feedback and assessment messages.</li>
                <li>
                  Information offered by users of the Services as part of
                  support enquiries.
                </li>
                <li>Other data added by the Controller from time to time.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className={classes.th}>Sensitive Data:</td>
            <td className={classes.td}>
              No sensitive data will be processed or transferred and shall not
              be contained in the content of or attachments to, emails.
            </td>
          </tr>
          <tr>
            <td className={classes.th}>
              The frequency of the processing and transfer (e.g. whether the
              data is transferred on a one-off or continuous basis):
            </td>
            <td className={classes.td}>
              Continuous basis for the duration of the Agreement.
            </td>
          </tr>
          <tr>
            <td className={classes.th}>Nature of the processing:</td>
            <td className={classes.td}>
              Processing operations include but are not limited to processing:
              <ol>
                <li>
                  remote server data (IP, Port, metrics data endpoint for each
                  service configured by Customer); and
                </li>
                <li>
                  metrics and logs from services configured and monitored by
                  Customer
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td className={classes.th}>
              Purpose(s) of the data transfer and further processing:
            </td>
            <td className={classes.td}>
              Personal Data is transferred to sub-contractors who need to
              process some of the Personal Data in order to provide their
              services to the Processor as part of the Services provided by the
              Processor to the Controller.
            </td>
          </tr>
          <tr>
            <td className={classes.th}>
              The period for which the Personal Data will be retained, or, if
              that is not possible, the criteria used to determine that period:
            </td>
            <td className={classes.td}>
              Unless agreed otherwise in writing, for the duration of the
              Agreement, subject to clause 14 of the DPA.
            </td>
          </tr>
          <tr>
            <td className={classes.th}>
              For transfers to (Sub-) processors, also specify subject matter,
              nature and duration of the processing:
            </td>
            <td className={classes.td}>
              The Sub-processor list published at:
              <a href="https://nodemonitoring.io/sub-processors">
                https://nodemonitoring.io/sub-processors
              </a>{" "}
              sets out the Personal Data processed by each Sub-processor and the
              services provided by each Sub-processor.
            </td>
          </tr>
        </table>
        <dl className={classes.listTitle}>
          <dt className={classes.subtitleFirst}>C.</dt>
          <dd className={classes.subtitle}>COMPETENT SUPERVISORY AUTHORITY</dd>
        </dl>
        <table className={classes.table}>
          <tr>
            <td className={classes.th}>
              Identify the competent supervisory authority/ies (e.g. in
              accordance with Clause 13 of the SCCs)
            </td>
            <td className={classes.td}>
              Where the EU GDPR applies, the Irish Data Protection Authority -
              Data Protection Commission, (DPC).<br></br>
              <br></br>
              Where the UK GDPR applies, the UK Information Commissioner's
              Office, (ICO).<br></br>
              <br></br>
              Where the FADP applies, the Swiss Federal Data Protection and
              Information Commissioner, (FDPIC).<br></br>
              <br></br>
            </td>
          </tr>
        </table>
        <p className={classes.textTitle}>
          <strong>
            <u>Exhibit B</u>
          </strong>
        </p>
        <p className={classes.text}>
          <strong>
            Technical and Organisational Security Measures (Including Technical
            and Organisational Measures to Ensure the Security of Data)
          </strong>
        </p>
        <p className={classes.text}>
          Below is a description of the technical and organisational measures
          implemented by the Processor (including any relevant certifications)
          to ensure an appropriate level of security, taking into account the
          nature, scope, context and purpose of the processing, and the risks
          for the rights and freedoms of natural persons.
        </p>
        <p className={classes.text}>
          Where applicable this Exhibit B will serve as Annex II to the SCCs.
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.tdNoWidth}>
                <strong>Measure</strong>
              </th>
              <th className={classes.tdNoWidth}>
                <strong>Description</strong>
              </th>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures of pseudonymisation and encryption of Personal Data
              </td>
              <td className={classes.tdNoWidth}>
                For the purpose of transfer control, an encryption technology is
                used (e.g. remote access to the company network via two factor
                VPN tunnel and full disk encryption). The suitability of an
                encryption technology is measured against the protective
                purpose. <br></br>
                <br></br>All access is logged, monitored, and configured for
                alerting by security via a centralised Security Incident and
                Event Management (“SIEM”) system. <br></br>
                <br></br>The Controller’s archived data is encrypted at rest
                using AES256 bit encryption <br></br>
                <br></br>Data in transit is protected by Transport Layer
                Security (“TLS”).
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring ongoing confidentiality, integrity,
                availability and resilience of processing systems and services
              </td>
              <td className={classes.tdNoWidth}>
                Access to data necessary for the performance of the particular
                task is ensured within the systems and applications by a
                corresponding role and authorisation concept. In accordance to
                the “least privilege” and "need-to-know" principles, each role
                has only those rights which are necessary for the fulfilment of
                the task to be performed by the individual person. <br></br>
                <br></br>To maintain data access control, state of the art
                encryption technology is applied to the Personal Data itself
                where deemed appropriate to protect sensitive data based on
                risk.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring the ability to restore the availability
                and access to Personal Data in a timely manner in the event of a
                physical or technical incident
              </td>
              <td className={classes.tdNoWidth}>
                All our applications are built stateless and can be easily
                recreated in different geographical regions. <br></br>
                <br></br>The Processor maintains redundancy throughout its IT
                infrastructure in order to minimize the lack of availability to
                or loss of data. Backups are maintained hourly and daily in
                accordance with our backup procedures. The Processor maintains a
                disaster recovery policy and at least once per calendar year
                practice executing the policy.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Processes for regularly testing, assessing and evaluating the
                effectiveness of technical and organisational measures in order
                to ensure the security of the processing
              </td>
              <td className={classes.tdNoWidth}>
                The Processor conducts multiple internal audits. The Processor
                strives to automate audits hence the majority of our monitoring
                of its infrastructure is automated and running 24/7 and based on
                various frameworks (CIS, NEST etc.). The Processor obtains an
                external security and compliance audit once per calendar year.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for user identification and authorisation
              </td>
              <td className={classes.tdNoWidth}>
                Remote access to the data processing systems is only possible
                through the Processor’s secure VPN tunnel. If the users first
                authenticate to the secure VPN tunnel, after successful
                authentication authorisation is executed by providing a unique
                user name and password to a centralised directory service. All
                access attempts, successful and unsuccessful are logged and
                monitored.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for the protection of data during transmission
              </td>
              <td className={classes.tdNoWidth}>
                Data in transit is protected by Transport Layer Security
                (“TLS”).
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for the protection of data during storage
              </td>
              <td className={classes.tdNoWidth}>
                Personal Data is only retained internally, and on the third
                party data centre servers, which are covered by data centre
                certifications.<br></br>
                <br></br>The Controller’s archived data is encrypted at rest
                using AES256 bit encryption and data in transit is protected by
                Transport Layer Security (“TLS”).
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring physical security of locations at which
                Personal Data are processed
              </td>
              <td className={classes.tdNoWidth}>
                Due to their respective security requirements, business premises
                and facilities are subdivided into different security zones with
                different access authorisations. Third party data centres are
                monitored by security personnel. Access for employees is only
                possible with an encoded ID with a photo on it. All other
                persons have access only after having registered before (e.g. at
                the main entrance).<br></br>
                <br></br>Access to special security areas for remote maintenance
                is additionally protected by a separate access area. The
                constructional and substantive security standards comply with
                the security requirements for data centres.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring events logging
              </td>
              <td className={classes.tdNoWidth}>
                System inputs are recorded in the form of log files therefore it
                is possible to review retroactively whether and by whom Personal
                Data was entered, altered or deleted.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring system configuration, including default
                configuration
              </td>
              <td className={classes.tdNoWidth}>
                Our system configuration is based on the Security Technical
                Implementation Guides (STIG). System configuration is applied
                and maintained by software tools that ensure the system
                configurations do not deviate from the specifications.
                Deviations will be fixed automatically and reported to our SOC.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for internal IT and IT security governance and
                management
              </td>
              <td className={classes.tdNoWidth}>
                Employees are instructed to collect, process and use Personal
                Data only within the framework and for the purposes of their
                duties (e.g. service provision). At a technical level,
                multi-client capability includes separation of functions as well
                as appropriate separation of testing and production systems.
                <br></br>
                <br></br>The Controller’s Personal Data is stored in a way that
                logically separates it from other customer data.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring data minimisation
              </td>
              <td className={classes.tdNoWidth}>
                If Personal Data is no longer required for the purposes for
                which it was processed, it is deleted promptly. It should be
                noted that with each deletion, the Personal Data is only locked
                in the first instance and is then deleted for good with a
                certain delay. This is done in order to prevent accidental
                deletions or possible intentional damage.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring data quality
              </td>
              <td className={classes.tdNoWidth}>
                All of the data that the Processor possesses is provided by the
                Controller. The Processor does not assess the quality of the
                data provided by the Controller. The Processor provides
                reporting tools within our product to help the Controller
                understand and validate the data that is stored.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring limited data retention
              </td>
              <td className={classes.tdNoWidth}>
                The Processor uses a data classification scheme for all data
                that it stores and our retention policy specifies how each type
                of data is retained. When a record with Personal Data is deleted
                then it will be permanently evicted from our active databases.
                The data is retained in our backups until they are rotated out
                by more recent backups per the data retention policy.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for ensuring accountability
              </td>
              <td className={classes.tdNoWidth}>
                The Processor internally reviews its information security
                policies semi-annually to ensure they it is still relevant and
                are being followed. All employees that handle sensitive data
                must acknowledge the information security policies. These
                employees are re-trained on information security policies once
                per year. A disciplinary policy is in place for employees that
                do not adhere to information security policies.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures for allowing data portability and ensuring erasure
              </td>
              <td className={classes.tdNoWidth}>
                The Services have built-in tools that allows the Controller to
                export and permanently erase data.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                Measures to be taken by the (Sub-) processor to be able to
                provide assistance to the Controller (and, for transfers from a
                Processor to a Sub- processor, to the Data Exporter).
              </td>
              <td className={classes.tdNoWidth}>
                The transfer of Personal Data to a third party (e.g. customers,
                sub- contractors, service providers) is only made if a
                corresponding contract exists, and only for the specific
                purposes. If Personal Data is transferred outside the EEA, the
                Processor provides that an adequate level of data protection
                exists at the target location or organisation in accordance with
                the European Union's data protection requirements, e.g. by
                employing contracts based on the EU SCCs.
              </td>
            </tr>
          </table>
        </div>
        <p className={classes.textTitle}>
          <strong>
            <u>Exhibit C</u>
          </strong>
        </p>
        <p className={classes.text}>
          <strong>
            International Data Transfer Addendum to the EU Commission Standard
            Contractual Clauses
          </strong>
        </p>
        <p className={classes.text}>
          This Addendum has been issued by the Information Commissioner for
          Parties making Restricted Transfers. The Information Commissioner
          considers that it provides Appropriate Safeguards for Restricted
          Transfers when it is entered into as a legally binding contract.
        </p>
        <p className={classes.text}>
          <strong>Part 1: Tables</strong>
        </p>
        <p className={classes.text}>
          <strong>
            <u>Table 1: Parties</u>
          </strong>
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <td className={classes.tdNoWidth}>
                <strong>Start date</strong>
              </td>
              <td colSpan={2} className={classes.tdNoWidth}>
                The date set out in Annex I of the Approved EU SCCs.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                <strong>The Parties</strong>
              </td>
              <td className={classes.tdNoWidth}>
                <strong>Exporter (who sends the Restricted Transfer)</strong>
              </td>
              <td className={classes.tdNoWidth}>
                <strong>Importer (who receives the Restricted Transfer)</strong>
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                <strong>Parties’ details</strong>
              </td>
              <td className={classes.tdNoWidth}>
                As set out in Annex I of the Approved EU SCCs.
              </td>
              <td className={classes.tdNoWidth}>
                As set out in Annex I of the Approved EU SCCs.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                <strong>Key Contact</strong>
              </td>
              <td className={classes.tdNoWidth}>
                As set out in Annex I of the Approved EU SCCs.
              </td>
              <td className={classes.tdNoWidth}>
                As set out in Annex I of the Approved EU SCCs.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                <strong>
                  Signature (if required for the purposes of Section 2)
                </strong>
              </td>
              <td className={classes.tdNoWidth}>See the Agreement.</td>
              <td className={classes.tdNoWidth}>See the Agreement.</td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          <strong>
            <u>Table 2: Selected SCCs, Modules and Selected Clauses</u>
          </strong>
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <td colSpan={2} className={classes.tdNoWidth}>
                <strong>Addendum EU SCCs</strong>
              </td>
              <td colSpan={4} className={classes.tdNoWidth}>
                the Approved EU SCCs, including the Appendix Information and
                with only the following modules, clauses or optional provisions
                of the Approved EU SCCs brought into effect for the purposes of
                this Addendum:
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Module</td>
              <td className={classes.tdNoWidth}>Module in operation</td>
              <td className={classes.tdNoWidth}>Clause 11 (Option)</td>
              <td className={classes.tdNoWidth}>
                Сlause 9a General Authorisation
              </td>
              <td className={classes.tdNoWidth}>Clause 9a (Time period)</td>
              <td className={classes.tdNoWidth}>
                Is personal data received from the Importer combined with
                personal data collected by the Exporter?
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>1</td>
              <td className={classes.tdNoWidth}>no</td>
              <td className={classes.tdNoWidth}>not used</td>
              <td className={classes.tdNoWidth}>-</td>
              <td className={classes.tdNoWidth}>-</td>
              <td className={classes.tdNoWidth}>-</td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>2</td>
              <td className={classes.tdNoWidth}>yes</td>
              <td className={classes.tdNoWidth}>not used</td>
              <td className={classes.tdNoWidth}>Yes</td>
              <td className={classes.tdNoWidth}>30 days</td>
              <td className={classes.tdNoWidth}>-</td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>3</td>
              <td className={classes.tdNoWidth}>yes</td>
              <td className={classes.tdNoWidth}>not used</td>
              <td className={classes.tdNoWidth}>Yes</td>
              <td className={classes.tdNoWidth}>30 days</td>
              <td className={classes.tdNoWidth}>-</td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>4</td>
              <td className={classes.tdNoWidth}>yes</td>
              <td className={classes.tdNoWidth}>not used</td>
              <td className={classes.tdNoWidth}>-</td>
              <td className={classes.tdNoWidth}>-</td>
              <td className={classes.tdNoWidth}>-</td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          <strong>
            <u>Table 3: Appendix Information</u>
          </strong>
        </p>
        <p className={classes.text}>
          <strong>“Appendix Information”</strong> means the information which
          must be provided for the selected modules as set out in the Appendix
          of the Approved EU SCCs (other than the Parties), and which for this
          Addendum is set out in:
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <td className={classes.tdNoWidth}>Annex 1A:</td>
              <td className={classes.tdNoWidth}>
                List of Parties: As set out in Annex I of the Approved EU SCCs
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Annex 1B:</td>
              <td className={classes.tdNoWidth}>
                Description of Transfer: As set out in Annex I of the Approved
                EU SCCs
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Annex II:</td>
              <td className={classes.tdNoWidth}>
                Technical and organisational measures including technical and
                organisational measures to ensure the security of the data: As
                set out in Annex II of the Approved EU SCCs
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Annex III:</td>
              <td className={classes.tdNoWidth}>
                List of Sub-processors: As set out in Annex I of the Approved EU
                SCCs
              </td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          <strong>
            <u>
              Table 4: Ending this Addendum when the Approved Addendum Changes
            </u>
          </strong>
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <td className={classes.tdNoWidth}>
                <strong>
                  Ending this Addendum when the Approved Addendum changes
                </strong>
              </td>
              <td className={classes.tdNoWidth}>
                Which Parties may end this Addendum as set out in Section 19:
                <ul>
                  <li>Importer</li>
                  <li>Exporter</li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          <strong>Part 2: Mandatory Clauses</strong>
        </p>
        <p className={classes.text}>
          <strong>
            <u>Entering into this Addendum</u>
          </strong>
        </p>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>1.</dt>
          <dd className={classes.listText}>
            Each Party agrees to be bound by the terms and conditions set out in
            this Addendum, in exchange for the other Party also agreeing to be
            bound by this Addendum.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>2.</dt>
          <dd className={classes.listText}>
            Although Annex 1A and Clause 7 of the Approved EU SCCs require
            signature by the Parties, for the purpose of making Restricted
            Transfers, the Parties may enter into this Addendum in any way that
            makes them legally binding on the Parties and allows data subjects
            to enforce their rights as set out in this Addendum. Entering into
            this Addendum will have the same effect as signing the Approved EU
            SCCs and any part of the Approved EU SCCs.
          </dd>
        </dl>
        <p className={classes.text}>
          <strong>
            <u>Interpretation of this Addendum</u>
          </strong>
        </p>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>3.</dt>
          <dd className={classes.listText}>
            Where this Addendum uses terms that are defined in the Approved EU
            SCCs those terms shall have the same meaning as in the Approved EU
            SCCs. In addition, the following terms have the following meanings:
          </dd>
        </dl>

        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <td className={classes.tdNoWidth}>Addendum</td>
              <td className={classes.tdNoWidth}>
                This International Data Transfer Addendum which is made up of
                this Addendum incorporating the Addendum EU SCCs.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Addendum EU SCCs</td>
              <td className={classes.tdNoWidth}>
                The version(s) of the Approved EU SCCs which this Addendum is
                appended to, as set out in Table 2, including the Appendix
                Information.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Appendix Information</td>
              <td className={classes.tdNoWidth}>As set out in Table 3.</td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Appropriate Safeguards</td>
              <td className={classes.tdNoWidth}>
                The standard of protection over the personal data and of data
                subjects’ rights, which is required by UK Data Protection Laws
                when you are making a Restricted Transfer relying on standard
                data protection clauses under Article 46(2)(d) UK GDPR.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Approved Addendum</td>
              <td className={classes.tdNoWidth}>
                The template Addendum issued by the ICO and laid before
                Parliament in accordance with s119A of the Data Protection Act
                2018 on 2 February 2022, as it is revised under Section 18.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Approved EU SCCs</td>
              <td className={classes.tdNoWidth}>
                The Standard Contractual Clauses set out in the Annex of
                Commission Implementing Decision (EU) 2021/914 of 4 June 2021.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>ICO</td>
              <td className={classes.tdNoWidth}>
                The Information Commissioner.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>Restricted Transfer</td>
              <td className={classes.tdNoWidth}>
                A transfer which is covered by Chapter V of the UK GDPR.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>UK</td>
              <td className={classes.tdNoWidth}>
                The United Kingdom of Great Britain and Northern Ireland.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>UK Data Protection Laws</td>
              <td className={classes.tdNoWidth}>
                All laws relating to data protection, the processing of personal
                data, privacy and/or electronic communications in force from
                time to time in the UK, including the UK GDPR and the Data
                Protection Act 2018.
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>UK GDPR</td>
              <td className={classes.tdNoWidth}>
                As defined in section 3 of the Data Protection Act 2018.
              </td>
            </tr>
          </table>
        </div>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>4.</dt>
          <dd className={classes.listText}>
            This Addendum must always be interpreted in a manner that is
            consistent with UK Data Protection Laws and so that it fulfils the
            Parties’ obligation to provide the Appropriate Safeguards.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>5.</dt>
          <dd className={classes.listText}>
            If the provisions included in the Addendum EU SCCs amend the
            Approved SCCs in any way which is not permitted under the Approved
            EU SCCs or the Approved Addendum, such amendment(s) will not be
            incorporated in this Addendum and the equivalent provision of the
            Approved EU SCCs will take their place.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>6.</dt>
          <dd className={classes.listText}>
            If there is any inconsistency or conflict between UK Data Protection
            Laws and this Addendum, UK Data Protection Laws applies.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>7.</dt>
          <dd className={classes.listText}>
            If the meaning of this Addendum is unclear or there is more than one
            meaning, the meaning which most closely aligns with UK Data
            Protection Laws applies.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>8.</dt>
          <dd className={classes.listText}>
            Any references to legislation (or specific provisions of
            legislation) means that legislation (or specific provision) as it
            may change over time. This includes where that legislation (or
            specific provision) has been consolidated, re-enacted and/or
            replaced after this Addendum has been entered into.
          </dd>
        </dl>
        <p className={classes.text}>
          <strong>
            <u>Hierarchy</u>
          </strong>
        </p>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>9.</dt>
          <dd className={classes.listText}>
            Although Clause 5 of the Approved EU SCCs sets out that the Approved
            EU SCCs prevail over all related agreements between the parties, the
            parties agree that, for Restricted Transfers, the hierarchy in
            Section 11 will prevail.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>10.</dt>
          <dd className={classes.listText}>
            Where there is any inconsistency or conflict between the Approved
            Addendum and the Addendum EU SCCs (as applicable), the Approved
            Addendum overrides the Addendum EU SCCs, except where (and in so far
            as) the inconsistent or conflicting terms of the Addendum EU SCCs
            provides greater protection for data subjects, in which case those
            terms will override the Approved Addendum.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>11.</dt>
          <dd className={classes.listText}>
            Where this Addendum incorporates Addendum EU SCCs which have been
            entered into to protect transfers subject to the General Data
            Protection Regulation (EU) 2016/679 then the Parties acknowledge
            that nothing in this Addendum impacts those Addendum EU SCCs.
          </dd>
        </dl>
        <p className={classes.text}>
          <strong>
            <u>Incorporation of and changes to the EU SCCs</u>
          </strong>
        </p>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>12.</dt>
          <dd className={classes.listText}>
            This Addendum incorporates the Addendum EU SCCs which are amended to
            the extent necessary so that:{" "}
            <ol type="a">
              <li>
                together they operate for data transfers made by the data
                exporter to the data importer, to the extent that UK Data
                Protection Laws apply to the data exporter’s processing when
                making that data transfer, and they provide Appropriate
                Safeguards for those data transfers;
              </li>
              <li>
                Sections 9 to 11 override Clause 5 (Hierarchy) of the Addendum
                EU SCCs; and
              </li>
              <li>
                this Addendum (including the Addendum EU SCCs incorporated into
                it) is (1) governed by the laws of England and Wales and (2) any
                dispute arising from it is resolved by the courts of England and
                Wales, in each case unless the laws and/or courts of Scotland or
                Northern Ireland have been expressly selected by the Parties.
              </li>
            </ol>
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>13.</dt>
          <dd className={classes.listText}>
            Unless the Parties have agreed alternative amendments which meet the
            requirements of Section 12, the provisions of Section 15 will apply.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>14.</dt>
          <dd className={classes.listText}>
            No amendments to the Approved EU SCCs other than to meet the
            requirements of Section 12 may be made.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>15.</dt>
          <dd className={classes.listText}>
            The following amendments to the Addendum EU SCCs (for the purpose of
            Section 12) are made:
            <ol type="a">
              <li>
                References to the “Clauses” means this Addendum, incorporating
                the Addendum EU SCCs;
              </li>
              <li>
                In Clause 2, delete the words:<br></br>
                <br></br>“and, with respect to data transfers from controllers
                to processors and/or processors to processors, standard
                contractual clauses pursuant to Article 28(7) of Regulation (EU)
                2016/679”;
              </li>
              <li>
                Clause 6 (Description of the transfer(s)) is replaced with:
                <br></br>
                <br></br>“The details of the transfers(s) and in particular the
                categories of personal data that are transferred and the
                purpose(s) for which they are transferred are those specified in
                Annex I.B where UK Data Protection Laws apply to the data
                exporter’s processing when making that transfer.”;
              </li>
              <li>
                Clause 8.7(i) of Module 1 is replaced with:<br></br>
                <br></br>“it is to a country benefitting from adequacy
                regulations pursuant to Section 17A of the UK GDPR that covers
                the onward transfer”;
              </li>
              <li>
                Clause 8.8(i) of Modules 2 and 3 is replaced with:<br></br>
                <br></br>“the onward transfer is to a country benefitting from
                adequacy regulations pursuant to Section 17A of the UK GDPR that
                covers the onward transfer;”
              </li>
              <li>
                References to “Regulation (EU) 2016/679”, “Regulation (EU)
                2016/679 of the European Parliament and of the Council of 27
                April 2016 on the protection of natural persons with regard to
                the processing of personal data and on the free movement of such
                data (General Data Protection Regulation)” and “that Regulation”
                are all replaced by “UK Data Protection Laws”. References to
                specific Article(s) of “Regulation (EU) 2016/679” are replaced
                with the equivalent Article or Section of UK Data Protection
                Laws;
              </li>
              <li>References to Regulation (EU) 2018/1725 are removed;</li>
              <li>
                References to the “European Union”, “Union”, “EU”, “EU Member
                State”, “Member State” and “EU or Member State” are all replaced
                with the “UK”;
              </li>
              <li>
                The reference to “Clause 12(c)(i)” at Clause 10(b)(i) of Module
                one, is replaced with “Clause 11(c)(i)”;
              </li>
              <li>Clause 13(a) and Part C of Annex I are not used;</li>
              <li>
                The “competent supervisory authority” and “supervisory
                authority” are both replaced with the “Information
                Commissioner”;
              </li>
              <li>
                In Clause 16(e), subsection (i) is replaced with:<br></br>
                <br></br>“the Secretary of State makes regulations pursuant to
                Section 17A of the Data Protection Act 2018 that cover the
                transfer of personal data to which these clauses apply;”;
              </li>
              <li>
                Clause 17 is replaced with:<br></br>
                <br></br>“These Clauses are governed by the laws of England and
                Wales.”;
              </li>
              <li>
                Clause 18 is replaced with:<br></br>
                <br></br>“Any dispute arising from these Clauses shall be
                resolved by the courts of England and Wales. A data subject may
                also bring legal proceedings against the data exporter and/or
                data importer before the courts of any country in the UK. The
                Parties agree to submit themselves to the jurisdiction of such
                courts.”; and
              </li>
              <li>
                The footnotes to the Approved EU SCCs do not form part of the
                Addendum, except for footnotes 8, 9, 10 and 11.
              </li>
            </ol>
          </dd>
        </dl>
        <p className={classes.text}>
          <strong>
            <u>Amendments to this Addendum</u>
          </strong>
        </p>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>16.</dt>
          <dd className={classes.listText}>
            The Parties may agree to change Clauses 17 and/or 18 of the Addendum
            EU SCCs to refer to the laws and/or courts of Scotland or Northern
            Ireland.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>17.</dt>
          <dd className={classes.listText}>
            If the Parties wish to change the format of the information included
            in Part 1: Tables of the Approved Addendum, they may do so by
            agreeing to the change in writing, provided that the change does not
            reduce the Appropriate Safeguards.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>18.</dt>
          <dd className={classes.listText}>
            From time to time, the ICO may issue a revised Approved Addendum
            which:
            <ol type="a">
              <li>
                makes reasonable and proportionate changes to the Approved
                Addendum, including correcting errors in the Approved Addendum;
                and/or
              </li>
              <li>reflects changes to UK Data Protection Laws;</li>
            </ol>
            The revised Approved Addendum will specify the start date from which
            the changes to the Approved Addendum are effective and whether the
            Parties need to review this Addendum including the Appendix
            Information. This Addendum is automatically amended as set out in
            the revised Approved Addendum from the start date specified.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>19.</dt>
          <dd className={classes.listText}>
            If the ICO issues a revised Approved Addendum under Section 18, if
            any Party selected in Table 4 “Ending the Addendum when the Approved
            Addendum changes”, will as a direct result of the changes in the
            Approved Addendum have a substantial, disproportionate and
            demonstrable increase in:
            <ol type="a">
              <li>
                its direct costs of performing its obligations under the
                Addendum; and/or
              </li>
              <li>its risk under the Addendum,</li>
            </ol>
            and in either case it has first taken reasonable steps to reduce
            those costs or risks so that it is not substantial and
            disproportionate, then that Party may end this Addendum at the end
            of a reasonable notice period, by providing written notice for that
            period to the other Party before the start date of the revised
            Approved Addendum.
          </dd>
        </dl>
        <dl className={classes.list}>
          <dt className={classes.listNumber}>20.</dt>
          <dd className={classes.listText}>
            The Parties do not need the consent of any third party to make
            changes to this Addendum, but any changes must be made in accordance
            with its terms.
          </dd>
        </dl>
      </div>
    </>
  );
};
