import React from "react";
import { makeStyles } from "tss-react/mui";
import { AnimateComponentOnScroll } from "../../animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  advantageSection: {
    margin: "7.4vh 18% 11vh",
    textAlign: "center",
    overflowX: "hidden",
    [`@media screen and (max-width: 1600px)`]: {
      margin: "8vh 12% 11vh",
    },
    [`@media screen and (max-width: 1300px)`]: {
      margin: "50px 8% 70px",
    },
    [`@media screen and (max-width: 700px)`]: {
      margin: "60px 12% 80px",
    },
  },
  advantageTitle: {
    ...theme.typography.h2,

    [`@media screen and (max-width: 700px)`]: {
      fontSize: "24px",
    },
  },
  advantages: {
    marginTop: "9vh",
    display: "flex",
    justifyContent: "space-around",
    [`@media screen and (max-width: 1600px)`]: {
      marginTop: "50px",
    },
    [`@media screen and (max-width: 700px)`]: {
      marginTop: "50px",
      flexDirection: "column",
      gap: "3vh",
    },
  },
  advantageImage: {
    marginBottom: "5.1vh",
    width: "9.5vw",
    height: "9.5vw",

    [`@media screen and (max-width: 1600px)`]: {
      marginBottom: "50px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      marginBottom: "30px",
      width: "12vw",
      height: "12vw",
    },
    [`@media screen and (max-width: 700px)`]: {
      marginBottom: "30px",
      width: "30vw",
      height: "30vw",
    },
  },
  advantageText: {
    ...theme.typography.button1,
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "16px",
    },
  },
}));

interface AdvantageSectionProps {}

export const AdvantageSection: React.FC<AdvantageSectionProps> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.advantageSection}>
      <AnimateComponentOnScroll enterDirection="right">
        <>
          <h2 className={classes.advantageTitle}>
            Being a node operator is tough. NMS has got your back.
          </h2>
        </>
      </AnimateComponentOnScroll>
      <div className={classes.advantages}>
        <AnimateComponentOnScroll enterDirection="right" delay="0.5s">
          <>
            <div>
              <img
                className={classes.advantageImage}
                src="/images/start-soa.png"
                alt="soa"
              />
              <div className={classes.advantageText}>
                <strong>State-of-the-art</strong> dashboards.
              </div>
            </div>
          </>
        </AnimateComponentOnScroll>
        <AnimateComponentOnScroll enterDirection="right" delay="1s">
          <>
            <div>
              <img
                className={classes.advantageImage}
                src="/images/start-tuned.png"
                alt="tuned"
              />
              <div className={classes.advantageText}>
                <strong>Finely tuned</strong> alerting stack.
              </div>
            </div>
          </>
        </AnimateComponentOnScroll>
        <AnimateComponentOnScroll enterDirection="right" delay="1.5s">
          <>
            <div>
              <img
                className={classes.advantageImage}
                src="/images/start-on-call.png"
                alt="oncall"
              />
              <div className={classes.advantageText}>
                Reliable <strong>OnCall schedules.</strong>
              </div>
            </div>
          </>
        </AnimateComponentOnScroll>
      </div>
    </div>
  );
};
