import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    lineHeight: "1.6",
    padding: "0 70px 30px",

    [`@media screen and (max-width: 1200px)`]: {
      padding: "0 50px 30px",
    },
    [`@media screen and (max-width: 700px)`]: {
      padding: "0 20px 30px",
    },
  },
  header: {
    padding: "50px 0 30px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "1.8em",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "1.5em",
    },
  },
  subHeader: {
    paddingTop: "20px",
    fontSize: "18px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "16px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "14px",
    },
  },
  text: {
    fontSize: "16px",
    paddingBottom: "90px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  tableContainer: {
    overflow: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },
  th: {
    background: "#f4f4f4",
    color: "#333",
    fontWeight: "bold",
    padding: "8px",
    border: "1px solid #ddd",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  td: {
    padding: "8px",
    border: "1px solid #ddd",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
}));

interface SubProcessorsPageProps {}

export const SubProcessorsPage: React.FC<SubProcessorsPageProps> = () => {
  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={classes.contentContainer}>
        <h1 className={classes.header}>SUB-PROCESSOR LIST</h1>
        <p className={classes.text}>
          The Company uses the following sub-processors to assist in providing
          the Services. Depending upon the Services ordered pursuant to and set
          out in the Agreement, the sub-processors include:
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.th}>Sub-processor</th>
                <th className={classes.th}>Purpose</th>
                <th className={classes.th}>Data</th>
                <th className={classes.th}>Location of sub-processor</th>
                <th className={classes.th}>Transfer Mechanism</th>
                <th className={classes.th}>Supplemental Measures</th>
                <th className={classes.th}>Contact Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.td}>
                  <strong>OVH US LLC dba OVHcloud</strong>
                </td>
                <td className={classes.td}>Hosting provider</td>
                <td className={classes.td}>
                  All application data except payment details
                </td>
                <td className={classes.td}>USA</td>
                <td className={classes.td}>
                  <a
                    href="https://www.dataprivacyframework.gov/s/participant-search"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DPF
                  </a>
                </td>
                <td className={classes.td}>Non required</td>
                <td className={classes.td}>
                  <a href="mailto:suzanne.marisa@us.ovhcloud.com">
                    suzanne.marisa@us.ovhcloud.com
                  </a>
                  <br></br>
                  <br></br>11950 Democracy Drive, Suite 300; Reston, VA 20191,
                  USA
                </td>
              </tr>
              <tr>
                <td className={classes.td}>
                  <strong>Microsoft Corporation</strong>
                </td>
                <td className={classes.td}>Outlook emails</td>
                <td className={classes.td}>
                  Personal data included in e-mails, documents and other data
                  transferred in an electronic form in the context of using
                  Outlook
                </td>
                <td className={classes.td}>USA</td>
                <td className={classes.td}>
                  <a
                    href="https://www.dataprivacyframework.gov/s/participant-search"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DPF
                  </a>
                </td>
                <td className={classes.td}>Non required</td>
                <td className={classes.td}>
                  <a href="mailto:dpoffice@microsoft.com">
                    dpoffice@microsoft.com
                  </a>
                  <br></br>
                  <br></br>One Microsoft Way, Redmond, Washington, Redmond, WA,
                  USA
                </td>
              </tr>
              <tr>
                <td className={classes.td}>
                  <strong>Pipedrive OU</strong>
                </td>
                <td className={classes.td}>CRM services</td>
                <td className={classes.td}>
                  IP addresses, browser types, operating systems, ISPs, platform
                  types, device types, mobile device identifiers such as make
                  and model, and mobile carrier.<br></br>
                  <br></br>
                  Personal identification data: full name, address, phone
                  number, and email address.<br></br>
                  <br></br>
                  Financial data: tax ID, VAT number, bank account number,
                  invoices, and credit notes
                </td>
                <td className={classes.td}>Estonia</td>
                <td className={classes.td}>
                  <a
                    href="https://www.dataprivacyframework.gov/s/participant-search"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DPF
                  </a>
                </td>
                <td className={classes.td}>Non required</td>
                <td className={classes.td}>
                  <a href="mailto:Phillip.mellet@pipedrive.com">
                    Phillip.mellet@pipedrive.com
                  </a>
                  <br></br>
                  <br></br>Mustamäetee 3a, Tallinn, Harjumaa, 10615, Estonia
                </td>
              </tr>
              <tr>
                <td className={classes.td}>
                  <strong>Slack Technologies LLC</strong>
                </td>
                <td className={classes.td}>
                  Helpdesk platform providing customer support
                </td>
                <td className={classes.td}>
                  Email, Name, Phone, Job Title, IP Address
                </td>
                <td className={classes.td}>Ireland</td>
                <td className={classes.td}>
                  <a
                    href="https://www.dataprivacyframework.gov/s/participant-search"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DPF
                  </a>
                </td>
                <td className={classes.td}>Non required</td>
                <td className={classes.td}>
                  <a href="mailto:privacy@salesforce.com">
                    privacy@salesforce.com
                  </a>
                  <br></br>
                  <br></br>Salesforce, Tower 60 R801, North Dock, Dublin,
                  Ireland
                </td>
              </tr>
              {/* Repeat rows as needed */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
