import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    lineHeight: "1.6",
    padding: "0 70px 30px",

    [`@media screen and (max-width: 1200px)`]: {
      padding: "0 50px 30px",
    },
    [`@media screen and (max-width: 700px)`]: {
      padding: "0 20px 30px",
    },
  },
  header: {
    padding: "50px 0 30px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "1.8em",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "1.5em",
    },
  },
  subHeader: {
    paddingTop: "20px",
    fontSize: "21px",
    fontWeight: "700",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "19px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "17px",
    },
  },
  text: {
    fontSize: "16px",
    marginBottom: "20px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  tableContainer: {
    overflow: "auto",
  },
  table: {
    marginTop: "20px",
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid black",
  },
  th: {
    width: "15%",
    border: "1px solid black",
    textAlign: "left",
    padding: "10px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  td: {
    width: "85%",
    padding: "10px",
    textAlign: "left",
    border: "1px solid black",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  tdNoWidth: {
    padding: "10px",
    textAlign: "left",
    border: "1px solid black",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  description: {
    paddingBottom: "60px",
  },
}));

interface PrivacyPageProps {}

export const PrivacyPage: React.FC<PrivacyPageProps> = () => {
  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={classes.contentContainer}>
        <h1 className={classes.header}>PRIVACY POLICY</h1>
        <div className={classes.description}>
          <p className={classes.text}>
            This privacy policy, (“<strong>Privacy Policy</strong>”) and any
            other documents referred to in it, set out the basis on which we
            collect and process your personal data as a data controller when you
            use our website or services.
          </p>
          <p className={classes.text}>
            Please read the following carefully to understand our views and
            practices regarding your personal data and how we will treat it. By
            visiting{" "}
            <a
              href="https://nodemonitoring.io"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              https://nodemonitoring.io
            </a>
            , (the “<strong>Site”</strong>”) or using our services or apps, (“
            <strong>Services”</strong>”) you are accepting and consenting to the
            practices described in this Privacy Policy.
          </p>
          <p>
            Please note: <br></br>
            This Privacy Policy does not apply to any data you provide to us
            when we process personal data on your behalf as your data processor
            i.e. where we process customer data within the cloud service we
            provide to you, as a business to business service provider.
          </p>
          <p>
            The Site and Services are not intended for use by children and we do
            not knowingly collect information relating to children.
          </p>
        </div>
        <h2 className={classes.subHeader}>Data controller</h2>
        <p className={classes.text}>
          For the purposes of EU and UK data protection laws and any applicable
          national implementing laws, regulations and secondary legislation
          relating to the processing of personal data (together “
          <strong>Data Protection Law</strong>”), the data controller is Node
          Monitoring FZCO of Dubai Silicon Oasis, DDP, Building A1, Dubai,
          United Arab Emirates (“<strong>we</strong>”, “<strong>us</strong>”, “
          <strong>our</strong>”).
        </p>
        <h2 className={classes.subHeader}>Data protection officer</h2>
        <p className={classes.text}>
          We have appointed a data protection officer (“<strong>DPO</strong>”)
          who is responsible for overseeing questions about this Privacy Policy
          who can be contacted as set out at the end of this Privacy Policy.
        </p>
        <h2 className={classes.subHeader}>Legal basis for processing</h2>
        <p className={classes.text}>
          We will only use your personal data when the law allows us to. The law
          requires us to have a legal basis for collecting and using your
          personal data. We rely on one or more of the following legal bases:
        </p>
        <ul className={classes.text}>
          <li>
            <strong>Performance of a contract with you:</strong>
            <br></br>Where we need to perform the contract we are about to enter
            into or have entered into with you.
          </li>
          <li>
            <strong>Legitimate interests:</strong>
            <br></br>Where it is necessary for our legitimate business interests
            (or those of a third party) and your interests and fundamental
            rights do not override those interests. For example to prevent fraud
            and enable us to give you the best and most secure customer
            experience.
          </li>
          <li>
            <strong>Legal obligation:</strong>
            <br></br>We may use your personal data where it is necessary for
            compliance with a legal obligation that we are subject to. We will
            identify the relevant legal obligation when we rely on this legal
            basis.
          </li>
          <li>
            <strong>Consent:</strong>
            <br></br>We rely on consent only where we have obtained your active
            agreement to use your personal data for a specified purpose, for
            example if you subscribe to an email newsletter.
          </li>
        </ul>
        <h2 className={classes.subHeader}>
          Personal data we may collect about you
        </h2>
        <p className={classes.text}>
          We may collect and process personal data about you. Personal data, or
          personally identifiable information, means any information about an
          individual from which that individual can be identified. We collect,
          use, store and transfer different kinds of personal data about you
          which we have grouped together as follows:
        </p>
        <ul className={classes.text}>
          <li>
            <strong>Identity Data:</strong> includes user name or similar
            identifier.
          </li>
          <li>
            <strong>Contact Data:</strong> includes billing address, email
            address, business name.
          </li>
          <li>
            <strong>Financial Data:</strong> includes bank account details.
          </li>
          <li>
            <strong>Transaction Data:</strong> includes details about payments
            from you and other details of products and services you have
            purchased from us.
          </li>
          <li>
            <strong>Technical Data:</strong> includes internet protocol (IP)
            address, your login data, browser type and version, time zone
            setting and location, browser plug-in types and versions, operating
            system and platform, device ID and other technology on the devices
            you use to access this Site or the Services.
          </li>
          <li>
            <strong>Profile Data:</strong> includes your username and password,
            purchases or orders made by you, your interests, preferences,
            feedback and survey responses.
          </li>
          <li>
            <strong>Usage Data:</strong> includes information about how you use
            our Site and Services, including the full Uniform Resource Locators
            (URL) clickstream to, through and from our Site and Services
            (including date and time); products you viewed or searched for; page
            response times, download errors, length of visits to certain pages,
            page interaction information (such as scrolling, clicks, and
            mouse-overs), and methods used to browse away from the page and any
            phone number used to call our customer service number.
          </li>
          <li>
            <strong>Marketing and Communication Data:</strong> includes your
            preferences in receiving marketing from us and our third parties and
            your communication preferences.
          </li>
          <li>
            <strong>Aggregated Data:</strong> We also collect, use and share
            aggregated data such as statistical or demographic data. Aggregated
            Data could be derived from your personal data but is not considered
            personal data in law as this data will not directly or indirectly
            reveal your identity. For example, we may aggregate your Usage Data
            to calculate the percentage of users accessing a specific website
            feature in order to analyse general trends in how users interact
            with the website. However, if we combine or connect Aggregated Data
            with your personal data so that it can directly or indirectly
            identify you, we treat the combined data as personal data which will
            be used in accordance with this Privacy Policy.
          </li>
        </ul>
        <h2 className={classes.subHeader}>
          If you fail to provide personal data
        </h2>
        <p className={classes.text}>
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you, and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with goods
          or services). In this case, we may have to cancel a product or service
          you have with us but we will notify you if this is the case at the
          time.
        </p>
        <h2 className={classes.subHeader}>How personal data is collected</h2>
        <p className={classes.text}>
          We use different methods to collect data from and about you including
          via:
        </p>
        <ul className={classes.text}>
          <li>
            <strong>Direct Interactions:</strong> You may give us your Identity,
            Contact and Financial Data when you fill in forms or correspond with
            us by post, phone, email or otherwise. This includes personal data
            you provide when you register to use our Site or to receive our
            newsletter, subscribe to use our Services, create an account to use
            our Site or Services, request marketing to be sent to you, search
            for a product or place an order on our Site, participate in
            discussion boards or other social media functions on our Site, enter
            a competition, promotion or survey, attend a conference or webinar,
            give us feedback or contact us and when you report a problem with
            our Site or Services.
          </li>
          <li>
            <strong>Purchases:</strong> If you make purchases via our Site or
            within any Services, or register for an event or webinar, we may
            require you to provide your Identity, Contact, Financial and
            Transaction Data.
          </li>
          <li>
            <strong>Community:</strong> If you register for an online community
            that we provide, we may ask you to provide us with Identity,
            Contact, Profile and Technical Data.
          </li>
          <li>
            <strong>Automated Technologies or Interactions:</strong> As you
            interact with our Services, Sites or emails, we automatically
            collect Technical Data about your device, browsing actions,
            patterns, Location Data and Usage Data. We collect this personal
            data by using cookies, server logs, web beacons, pixels, and other
            similar technologies about your device, and your use of our Site and
            Services. We may also receive Technical Data and Location Data about
            you if you visit other websites using our cookies. Please see the
            Cookie section below for further details.
          </li>
        </ul>
        <h2 className={classes.subHeader}>
          Personal data we receive from other sources
        </h2>
        <p className={classes.text}>
          We work closely with the third parties set out in our Third Party
          Supplier List which includes, for example, business partners,
          sub-contractors in technical, payment and delivery services,
          advertising networks, analytics providers, search information
          providers, credit reference agencies and we may receive the following
          personal data about you from them:
        </p>
        <ul className={classes.text}>
          <li>
            <strong>Technical Data:</strong> from analytics providers,
            advertising networks and search information providers.
          </li>
          <li>
            <strong>Contact, Transaction and Financial Data:</strong> from
            providers of technical, payment and delivery services.
          </li>
          <li>
            <strong>Identity and Contact Data:</strong> from providers of
            chat/communication/helpdesk services with customers including via
            email.
          </li>
          <li>
            <strong>Email Communications and Contact Data:</strong> from
            providers of email communications service providers.
          </li>
          <li>
            <strong>Business Contact and Financial Data:</strong> from CRM
            service providers who manage contacts and keep a record of
            communications/ interactions with customers.
          </li>
          <li>
            <strong>Contact Data and Financial Data:</strong> from cloud
            accounting systems that store email and names of persons sent
            invoices by email.
          </li>
        </ul>
        <h2 className={classes.subHeader}>
          Personal data we collect from other sources
        </h2>
        <p className={classes.text}>
          We also collect personal data about you from publicly available
          sources. We may combine this information with personal data provided
          by you. This helps us update, expand, and analyse our records,
          identify new customers, and create more tailored advertising to
          provide services that may be of interest to you. We also use this for
          the purposes of targeted advertising, delivering relevant email
          content, event promotion and profiling, determining eligibility and
          verifying Contact Data. The personal data we collect includes:
        </p>
        <ul className={classes.text}>
          <li>
            <strong>Identity and Contact Data:</strong> from publicly available
            sources such as Companies House.
          </li>
          <li>
            <strong>Identity, Contact and Profile Data:</strong> that is
            published about you on social media profiles: such as LinkedIn,
            Facebook, X.
          </li>
        </ul>
        <h2 className={classes.subHeader}>Cookies</h2>
        <p className={classes.text}>
          We use cookies or similar tracking technology, on our Site and in the
          Services to distinguish you from other users of our Site and Services.
          This helps us to provide you with a good experience when you browse
          our Site and also allows us to improve the Site and Services.
        </p>
        <p className={classes.text}>
          <strong>What are cookies</strong>
        </p>
        <p className={classes.text}>
          Cookies are small text files that are placed on your computer by
          websites that you visit. They are widely used in order to make
          websites work, or work more efficiently, as well as to provide
          information to the owners of the website. Cookies can be “persistent”
          or “session” cookies.
        </p>
        <p className={classes.text}>
          We use persistent cookies and session cookies.
        </p>
        <p className={classes.text}>
          <strong>Persistent Cookies</strong>
        </p>
        <p className={classes.text}>
          A persistent cookie is stored on a user’s device in between browser
          sessions which allows the preferences or actions of a user across the
          Site (or in some cases across different websites) to be remembered. We
          use persistent cookies to save your login information for future
          logins to the Site or Services.
        </p>
        <p className={classes.text}>
          <strong>Session Cookies</strong>
        </p>
        <p className={classes.text}>
          A session cookie allows the Site or Services to link your actions
          during a browser session. We use session cookies to enable certain
          features of the Site or Services, to better understand how you
          interact with the Site or Services and to monitor aggregate usage by
          users and web traffic routing on the Site. Unlike persistent cookies,
          session cookies are deleted from your computer when you log off from
          the Site or Services and then close your browser.
        </p>
        <p className={classes.text}>
          <strong>Which cookies we use and why</strong>
        </p>
        <p className={classes.text}>
          <strong>Strictly necessary cookies.</strong> These are cookies that
          are required for the operation of our Site. They include, for example,
          cookies that enable you to log into secure areas of our Site and a
          cookie that records your cookie preferences.
        </p>
        <p className={classes.text}>
          <strong>Analytics and tracking cookies.</strong> These cookies allow
          us to recognise and count the number of visitors and to see how
          visitors move around our Site when they are using it. This helps us to
          improve the way our Site and Services work, for example, by ensuring
          that users are finding what they are looking for easily.
        </p>
        <p className={classes.text}>
          <strong>Functional cookies.</strong> These cookies are used to
          recognise you when you return to our Site. This enables us to
          personalise our content for you, greet you by name and remember your
          preferences (for example, your choice of language or region).
        </p>
        <p className={classes.text}>
          <strong>Marketing cookies.</strong> These cookies record your visit to
          our Site, the pages you have visited and the links you have followed.
          We will use this information to make our Site and Services and the
          advertising displayed on them more relevant to your interests. We may
          also share this information with third parties for this purpose.
        </p>
        <p className={classes.text}>
          You can find more information about the individual cookies we use and
          the purposes for which we use them below.
        </p>
        <p className={classes.text}>
          <strong>
            <u>Strictly necessary cookies</u>
          </strong>
        </p>
        <p className={classes.text}>
          These cookies are strictly necessary to provide you with services
          available through our Site and to use some of its features, such as
          access to secure areas.
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>__tluid</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Assigns a random ID number to each visitor so that their policy
                consent and cookie consent preferences can be saved.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>app.termly.io</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                Termly{" "}
                <a href="https://termly.io/our-privacy-policy/">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>server_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>12 months</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>TERMLY_API_CACHE</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Used to store visitor’s consent result in order to improve
                performance of the consent banner.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>nodemonitoring.io</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                Termly{" "}
                <a href="https://termly.io/our-privacy-policy/">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>html_local_storage</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>1 year</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>csrf_token</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Protects against hacking and malicious actors.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>nodemonitoring.io</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                Django{" "}
                <a href="https://www.djangoproject.com/foundation/faq/">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>http_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>1 month</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>rc::f</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Used to track and analyse user behaviour to distinguish humans
                from bots or automated software.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>google.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                reCAPTCHA{" "}
                <a href="https://policies.google.com/privacy">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>html_local_storage</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>permanent cookie</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>_grecaptcha</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Stores a value used to verify that the user is not a bot.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>nodemonitoring.io</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                reCAPTCHA{" "}
                <a href="https://policies.google.com/privacy">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United Arab Emirates</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>html_local_storage</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>permanent cookie</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>_GRECAPTCHA</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Stores a value used to verify that the user is not a bot.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>google.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                reCAPTCHA{" "}
                <a href="https://policies.google.com/privacy">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>http_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>1 year</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>rc::d</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Used to track and analyse user behaviour to distinguish humans
                from bots or automated software.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>google.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                reCAPTCHA
                <a href="https://policies.google.com/privacy">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>html_local_storage</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>permanent cookie</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>rc::a</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Used to track and analyse user behaviour to distinguish humans
                from bots or automated software.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>google.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                reCAPTCHA
                <a href="https://policies.google.com/privacy">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>html_local_storage</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>permanent cookie</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>refreshToken</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Used to authenticate a user who logs into their account.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>nodemonitoring.io</td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United Arab Emirates</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>html_local_storage</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>after each session</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>accessToken</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Used to authenticate a user who logs into their account.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>nodemonitoring.io</td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United Arab Emirates</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>html_local_storage</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>after each session</td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          <strong>
            <u>Advertising Cookies</u>
          </strong>
        </p>
        <p className={classes.text}>
          These cookies are used to increase the relevance of advertising
          messages for users. They help ensure that advertisements are displayed
          correctly for advertisers and, in certain cases, select ads based on
          the users' interests.
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>VISITOR_INFO1_LIVE</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                YouTube is a Google-owned platform for hosting and sharing
                videos. YouTube collects user data through videos embedded in
                websites, which is aggregated with profile data from other
                Google services in order to display targeted advertising to web
                visitors across a broad range of their own and other websites.
                Used by Google in combination with SID to verify Google user
                account and most recent login time.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>youtube.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                YouTube
                <a href="https://business.safety.google/privacy/">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>server_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>6 month</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>YSC</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                YouTube is a Google-owned platform for hosting and sharing
                videos. YouTube collects user data through videos embedded in
                websites, which is aggregated with profile data from other
                Google services in order to display targeted advertising to web
                visitors across a broad range of their own and other websites.
                Used by Google in combination with SID to verify Google user
                account and most recent login time.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>youtube.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                YouTube
                <a href="https://business.safety.google/privacy/">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>server_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>after each session</td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          <strong>
            <u>Analytics and tracking cookies</u>
          </strong>
        </p>
        <p className={classes.text}>
          These cookies collect information that is used either in an aggregate
          form to help us understand how our Site is being used or how effective
          our marketing campaigns are, or to help us customize our Site for you.
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>_ga</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Records a particular ID used to come up with data about website
                usage by the user.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>nodemonitoring.io</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                Google Analytics{" "}
                <a href="https://policies.google.com/privacy">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>http_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>1.5 years</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>_ga_#</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Used to distinguish individual users by means of designation of
                a randomly generated number as client identifier, which allows
                calculation of visits and sessions.
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>nodemonitoring.io</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                Google Analytics{" "}
                <a href="https://policies.google.com/privacy">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>http_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>1.5 years</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>NID</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>
                Set by Google to set a unique user ID to remember user
                preferences. Persistent cookie that stays for 182 days
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>google.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>
                Google Analytics{" "}
                <a href="https://business.safety.google/privacy/">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>server_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>6 months</td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          <strong>
            <u>Unclassified cookies</u>
          </strong>
        </p>
        <p className={classes.text}>
          These are cookies that have not yet been categorized. We are in the
          process of classifying these cookies with the help of their providers.
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>VISITOR_PRIVACY_METADATA</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>-</td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>youtube.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>-</td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>server_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>6 months</td>
            </tr>
          </table>
        </div>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Name</th>
              <td className={classes.td}>
                <strong>Priority</strong>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>Purpose</th>
              <td className={classes.td}>-</td>
            </tr>
            <tr>
              <th className={classes.th}>Provider</th>
              <td className={classes.td}>google.com</td>
            </tr>
            <tr>
              <th className={classes.th}>Service</th>
              <td className={classes.td}>-</td>
            </tr>
            <tr>
              <th className={classes.th}>Country</th>
              <td className={classes.td}>United States</td>
            </tr>
            <tr>
              <th className={classes.th}>Type</th>
              <td className={classes.td}>server_cookie</td>
            </tr>
            <tr>
              <th className={classes.th}>Duration:</th>
              <td className={classes.td}>after each session</td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          You can set up your browser options, to stop your computer accepting
          cookies or to prompt you before accepting a cookie from the websites
          you visit. If you do not accept cookies, however, you may not be able
          to use the whole of the Site or all functionality of the Services. To
          adjust your cookie preferences click on the following link:{" "}
          <a href="#" className="termly-display-preferences">
            Consent Preferences
          </a>
        </p>
        <p className={classes.text}>
          To find out more about cookies, including how to see what cookies have
          been set and how to manage and delete them, visit{" "}
          <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>.
          To opt out of being tracked by Google Analytics across all websites
          visit{" "}
          <a href="http://tools.google.com/dlpage/gaoptout">
            http://tools.google.com/dlpage/gaoptout
          </a>
          .
        </p>
        <p className={classes.text}>
          <strong>Pixel-Tags</strong>
        </p>
        <p className={classes.text}>
          We may use "pixel tags," which are small graphic files that allow us
          to monitor the use of the Site and Services. A pixel tag can collect
          information such as the IP (Internet Protocol) address of the computer
          that downloaded the page on which the tag appears; the URL (Uniform
          Resource Locator) of the page on which the pixel tag appears; the time
          the page containing the pixel tag was viewed; the type of browser that
          fetched the pixel tag; and the identification number of any cookie on
          the computer previously placed by that server. When corresponding with
          you via HTML capable e-mail, we may use "format sensing" technology,
          which allows pixel tags to let us know whether you received and opened
          our e-mail.
        </p>
        <p className={classes.text}>
          <strong>Web Beacons</strong>
        </p>
        <p className={classes.text}>
          Some of our web pages may contain electronic images known as web
          beacons (sometimes known as clear gifs) that allow us to count users
          who have visited these pages. Web beacons collect only limited
          information which includes a cookie number; time and date of a page
          view; and a description of the page on which the web beacon resides.
          We may also carry web beacons placed by third party advertisers. These
          web beacons do not carry any personal data and are only used to track
          the effectiveness of a particular campaign.
        </p>
        <h2 className={classes.subHeader}>Uses made of your personal data</h2>
        <p className={classes.text}>
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data, and which of the legal bases
          we rely on to do so. We have also identified what our legitimate
          interests are where appropriate.
        </p>
        <p className={classes.text}>
          Note that we may process your personal data for more than one lawful
          basis depending on the specific purpose for which we are using your
          data. Please contact us if you need details about the specific legal
          ground we are relying on to process your personal data where more than
          one ground has been set out in the table below.
        </p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <tr>
              <th className={classes.th}>Purpose/Activity</th>
              <th className={classes.th}>Type of data</th>
              <th className={classes.th}>Lawful basis for processing</th>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To register you as a new customer
              </td>
              <td className={classes.tdNoWidth}>Identity Data, Contact Data</td>
              <td className={classes.tdNoWidth}>
                Performance of a contract with you
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To process and deliver your order including:
                <ul>
                  <li>Manage payments, fees and charges</li>
                  <li>Collect and recover money owed to us</li>
                </ul>
              </td>
              <td className={classes.tdNoWidth}>
                Identity Data, Contact Data, Financial Data, Transaction Data,
                Marketing and Communications Data
              </td>
              <td className={classes.tdNoWidth}>
                <ol>
                  <li>Performance of a contract with you</li>
                  <li>
                    Necessary for our legitimate interests (to recover debts due
                    to us)
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To manage our relationship with you which will include:
                <ul>
                  <li>
                    Notifying you about changes to our terms, this Privacy
                    Policy, the Site or Services
                  </li>
                  <li>Dealing with your requests, complaints and queries</li>
                </ul>
              </td>
              <td className={classes.tdNoWidth}>
                Identity Data, Contact Data, Profile Data, Marketing and
                Communications Data
              </td>
              <td className={classes.tdNoWidth}>
                <ol>
                  <li>Performance of a contract with you</li>
                  <li>Necessary to comply with a legal obligation</li>
                  <li>
                    Necessary for our legitimate interests (to keep our records
                    updated, to manage our relationship with you and to study
                    how customers use our products/services)
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To enable you to partake in a prize draw, competition, review or
                complete a survey
              </td>
              <td className={classes.tdNoWidth}>
                Identity Data, Contact Data, Profile Data, Usage Data, Marketing
                and Communications Data
              </td>
              <td className={classes.tdNoWidth}>
                <ol>
                  <li>Performance of a contract with you</li>
                  <li>
                    Necessary for our legitimate interests (to study how
                    customers use our products/services, to develop them and
                    grow our business)
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To administer and protect our business and this Site (including
                troubleshooting, data analysis, testing, system maintenance,
                support, updates, reporting and hosting of data)
              </td>
              <td className={classes.tdNoWidth}>
                Identity Data, Contact Data, Technical Data
              </td>
              <td className={classes.tdNoWidth}>
                <ol>
                  <li>
                    Necessary for our legitimate interests (for running our
                    business, provision of administration and IT services,
                    network security, to prevent fraud and in the context of a
                    business reorganisation or group restructuring exercise)
                  </li>
                  <li>Necessary to comply with a legal obligation</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To deliver relevant Site and Services content and online
                advertisements to you and measure or understand the
                effectiveness of the advertising we serve to you
              </td>
              <td className={classes.tdNoWidth}>
                Identity Data, Contact Data, Profile Data, Usage Data, Marketing
                and Communications Data, Technical Data
              </td>
              <td className={classes.tdNoWidth}>
                Necessary for our legitimate interests (to study how customers
                use our products/services, to develop them, to grow our business
                and to inform our marketing strategy)
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To use data analytics to improve our Site and Services, customer
                relationships and experiences and to measure the effectiveness
                of our communications and marketing
              </td>
              <td className={classes.tdNoWidth}>Technical Data, Usage Data</td>
              <td className={classes.tdNoWidth}>
                Necessary for our legitimate interests (to define types of
                customers for our products and services, to keep our website
                updated and relevant, to develop our business and to inform our
                marketing strategy)
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To send you relevant marketing communications and make
                personalised suggestions and recommendations to you about goods
                or services that may be of interest to you
              </td>
              <td className={classes.tdNoWidth}>
                Identity Data, Contact Data, Technical Data, Usage Data, Profile
                Data, Marketing and Communications Data
              </td>
              <td className={classes.tdNoWidth}>
                Necessary for our legitimate interests (to carry out direct
                marketing, develop our Site and Services and grow our business)
              </td>
            </tr>
            <tr>
              <td className={classes.tdNoWidth}>
                To enable you to apply to work for us
              </td>
              <td className={classes.tdNoWidth}>
                Identity Data, Contact Data, Financial Data, Technical Data
              </td>
              <td className={classes.tdNoWidth}>
                <ol>
                  <li>Performance of a contract with you</li>
                  <li>Necessary to comply with a legal obligation</li>
                  <li>
                    Necessary for our legitimate interests (to keep our records
                    updated) and to enable you to apply to work for us
                  </li>
                </ol>
              </td>
            </tr>
          </table>
        </div>
        <p className={classes.text}>
          We will not sell or rent your personal data to anyone.
        </p>
        <p className={classes.text}>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to obtain an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us.
        </p>
        <p className={classes.text}>
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </p>
        <h2 className={classes.subHeader}>Disclosure of your personal data</h2>
        <p className={classes.text}>
          <strong>Personal data we share with third parties.</strong> We may
          share your personal data where necessary with the third parties set
          out in our Third Party Supplier List for the purposes set out in the
          table above. Below is a summary of the types of third parties used:
        </p>
        <ul>
          <li>
            Any member of our group, which means our subsidiaries, our ultimate
            holding company and its subsidiaries, as defined in section 1159 of
            the UK Companies Act 2006.
          </li>
          <li>
            Business partners, suppliers and sub-contractors for the performance
            of any contract we enter into with them or you to provide services
            such as IT and system administration services, email communications,
            hosting services, backup services, credit card processing, research,
            development, marketing and customer support.
          </li>
          <li>
            Analytics and search engine providers that assist us in the
            improvement and optimisation of our Site and Services.
          </li>
          <li>
            Professional advisors acting as service providers to us in relation
            to the Site or Services - including lawyers, bankers, auditors, and
            insurers who provide consultancy, banking, legal, insurance and
            accounting services.
          </li>
          <li>
            Tax authorities, regulators and other authorities who require
            reporting of processing activities in certain circumstances.
          </li>
          <li>
            Advertisers and advertising networks that require the data to select
            and serve relevant adverts to you and others. We do not disclose
            personal data about identifiable individuals to our advertisers, but
            we may provide them with Aggregated Data about our users (for
            example, we may inform them that 250 men aged over 25 have clicked
            on their advertisement on any given day). We may also use such
            Aggregated Data to help advertisers reach the kind of audience they
            want to target (for example, women living in London). We may make
            use of the personal data we have collected from you to enable us to
            comply with our advertisers' wishes by displaying their
            advertisement to that target audience.
          </li>
          <li>
            Credit reference agencies for the purpose of assessing your credit
            score where this is a condition of us entering into a contract with
            you.
          </li>
        </ul>
        <p className={classes.text}>
          <strong>Personal data we disclose to third parties.</strong>We may
          disclose your personal data where necessary to third parties:
        </p>
        <ul>
          <li>
            In the event that we sell or buy any business or assets, in which
            case we may disclose your personal data to the prospective seller or
            buyer of such business or assets.
          </li>
          <li>
            If we or a member of our group of companies or substantially all of
            their assets are acquired by a third party, in which case personal
            data held by them about their customers will be one of the
            transferred assets.
          </li>
        </ul>
        <p className={classes.text}>
          If we are under a duty to disclose or share your personal data in
          order to comply with any legal obligation, or in order to enforce or
          apply our terms and conditions, terms of use and/or any other legal
          agreements; or to protect our rights, property, safety, our customers
          or others. This includes exchanging information with other companies
          and organisations for the purposes of fraud protection and credit risk
          reduction. We require all third parties to respect the security of
          your personal data and to treat it in accordance with applicable law.
          We do not allow our third-party service providers to use your personal
          data for their own purposes and only permit them to process your
          personal data for specified purposes and in accordance with our
          instructions.
        </p>
        <h2 className={classes.subHeader}>International transfers</h2>
        <p className={classes.text}>
          Our Services are global and your personal data may be stored and
          processed in any country where we have operations or our staff are
          located. We may transfer your personal data to service providers that
          carry out certain functions on our behalf. This may involve
          transferring personal data outside your country of residence to
          countries which have laws that do not provide the same level of data
          protection as your country of residence. When we share your personal
          data with our service providers, who are identified in our Third Party
          Supplier List, this will involve transferring your personal data to
          the countries set out in the Third Party Supplier List for each
          service provider.
        </p>
        <p className={classes.text}>
          When we transfer UK, EU or Swiss personal data to countries whose laws
          do not provide the same level of data protection as the UK, the EU or
          Switzerland, we always ensure that a similar degree of protection is
          afforded to your data by ensuring that one of the following applicable
          safeguards is in place:
        </p>
        <ul>
          <li>
            We will only transfer UK personal data outside of the UK to: (i)
            countries deemed by the ICO to provide an adequate level of
            protection for UK personal data; or (ii) entities located outside of
            the UK with whom standard contractual terms approved for use in the
            UK which give the transferred personal data the same protection as
            it has in the UK have been entered into, for example the
            International Data Transfer Addendum, (IDTA) to the European
            Commission’s standard contractual clauses for international data
            transfers or binding corporate rules (BCRs); or (iii) entities
            located in the USA certified under the UK Extension to the EU-U.S.
            DPF; or (iv) any entity located outside of the UK that is subject to
            any other transfer mechanism, bespoke contract, approved code of
            conduct or certification scheme approved by the ICO.
          </li>
          <li>
            We will only transfer EU personal data outside of the EEA to: (i)
            countries deemed by the European Commission to provide an adequate
            level of protection for EU personal data; or (ii) entities located
            outside of the EEA with whom standard contractual terms approved for
            use in the EU which give the transferred personal data the same
            protection as it has in the EU have been entered into, for example
            the European Commission’s standard contractual clauses for
            international data transfers, (EU SCCs) or binding corporate rules
            (BCRs); or (iii) entities located in the USA certified under the
            EU-U.S. DPF; or (iv) any entity located outside of the EEA that is
            subject to any other transfer mechanism, bespoke contract, approved
            code of conduct or certification scheme approved by the European
            Commission.
          </li>
          <li>
            We will only transfer Swiss personal data outside of Switzerland to:
            (i) countries deemed by the Swiss Data Protection Authority to
            provide an adequate level of protection for Swiss personal data; or
            (ii) entities located outside of Switzerland with whom standard
            contractual terms approved for use in Switzerland which give the
            transferred personal data the same protection as it has in
            Switzerland have been entered into, for example the European
            Commission’s standard contractual clauses for international data
            transfers, (EU SCCs) or binding corporate rules (BCRs); or (iii)
            entities located in the USA certified under the Swiss-U.S. DPF; or
            (iv) any entity located outside of Switzerland that is subject to
            any other transfer mechanism, bespoke contract, approved code of
            conduct or certification scheme approved by the Swiss Data
            Protection Authority.
          </li>
          <li>
            To obtain a copy of any of the above these contractual safeguards,
            please contact us as set out at the end of this Privacy Policy.
          </li>
        </ul>
        <p className={classes.text}>
          Please note that our Site and Services are accessible via the internet
          and may potentially be accessed by anyone around the world. Other
          users may access the Site or Services from outside the EEA,
          Switzerland or the UK. This means that where you chose to post your
          personal data on our Site or within the Services, it could be accessed
          from anywhere around the world and therefore a transfer of your
          personal data outside of the EEA, Switzerland or the UK may be deemed
          to have occurred.
        </p>
        <h2 className={classes.subHeader}>Data security</h2>
        <p className={classes.text}>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. For example, all information
          you provide to us is stored on our secure servers. Any credit card
          information or payment transactions will be encrypted using SSL
          technology.
        </p>
        <p className={classes.text}>
          Where we have given you (or where you have chosen) a password which
          enables you to access certain parts of the Site or Services, you are
          responsible for keeping this password confidential. We ask you not to
          share any password with anyone. In addition, we limit access to your
          personal data to those employees, agents, contractors and other third
          parties who have a business need to know. They will only process your
          personal data on our instructions and they are subject to a duty of
          confidentiality.
        </p>
        <p className={classes.text}>
          We have put in place procedures to deal with any personal data breach
          and will notify you and any applicable regulator of a breach where we
          are legally required to do so.
        </p>
        <p className={classes.text}>
          Unfortunately, the transmission of information via the Internet is not
          completely secure. Although we will endeavour to protect your personal
          data, we cannot guarantee the security of your personal data
          transmitted to our Site or the Services. Any transmission is at your
          own risk. Once we have received your personal data, we will use strict
          procedures and security features to try to prevent unauthorised
          access.
        </p>
        <h2 className={classes.subHeader}>Third party links</h2>
        <p className={classes.text}>
          Our Site and Services may, from time to time, contain links to and
          from third party websites, plug-ins and applications. Clicking on
          those links or enabling those connections may allow third parties to
          collect or share data about you. Please note that these third party
          websites, plug-ins and applications have their own privacy policies
          and that we do not accept any responsibility or liability for these
          policies. Please check these policies before you submit any personal
          data to these third party websites, plug-ins or applications.
        </p>
        <h2 className={classes.subHeader}>
          Use of blogs, forums and chat rooms
        </h2>
        <p className={classes.text}>
          Any information that you post to areas of the Site or Services that is
          viewable by others (for example, to a blog, forum or chat-room) will
          not be treated as proprietary, private, or confidential. We have no
          obligation to monitor such posts to the Site or Services or to
          exercise any editorial control over such posts; however, we reserve
          the right to review such posts and to remove any material that, in our
          judgment, is not appropriate. Posting, transmitting, promoting, using,
          distributing or storing content that could subject us to any legal
          liability, whether in tort or otherwise, or that is in violation of
          any applicable law or regulation, or otherwise contrary to commonly
          accepted community standards, is prohibited, including without
          limitation information and material protected by copyright, trademark,
          trade secret, nondisclosure or confidentiality agreements, or other
          intellectual property rights.
        </p>
        <h2 className={classes.subHeader}>Data retention</h2>
        <p className={classes.text}>
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected if for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint, if we reasonably believe there is
          a prospect of litigation in respect of our relationship with you, to
          comply with law enforcement requests, maintain security, prevent fraud
          and abuse, resolve disputes, enforce our legal agreements, or fulfil
          your request to “unsubscribe” from further messages from us.
        </p>
        <p className={classes.text}>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting or other requirements.
        </p>
        <p className={classes.text}>
          By law we have to keep basic information about our customers
          (including Contact Data, Identity Data, Financial Data and Transaction
          Data) for 7 years after they cease being customers for tax purposes.
        </p>
        <p className={classes.text}>
          This will be for as long as we provide access to the Site or Services
          to you, your account with us remains open or any period set out in any
          relevant contract you have with us.
        </p>
        <p className={classes.text}>
          In some circumstances we will anonymise your personal data (so that it
          can no longer be associated with your) after your account has been
          closed and we may use this for research or statistical purposes, in
          which case we may use this information indefinitely without further
          notice to you.
        </p>
        <p className={classes.text}>
          Please note: After you have closed your account or deleted information
          from your account, any information you have shared with others will
          remain visible. We do not control data that other users may have
          copied from the Site or Services. Your profile may continue to be
          displayed in the services of others (e.g. search engine results) until
          they refresh their cache.
        </p>
        <h2 className={classes.subHeader}>Your legal rights</h2>
        <p className={classes.text}>
          You have a number of rights under Data Protection Law in relation to
          your personal data. You have the right to:
        </p>
        <ul>
          <li>
            Request access to your personal data (commonly known as a “subject
            access request”). This enables you to receive a copy of the personal
            data we hold about you and to check that we are lawfully processing
            it.
          </li>
          <li>
            Request rectification of the personal data that we hold about you.
            This enables you to have any incomplete or inaccurate data we hold
            about you corrected, though we may need to verify the accuracy of
            the new data you provide to us.
          </li>
          <li>
            Request erasure of your personal data in certain circumstances. This
            enables you to ask us to delete or remove personal data where there
            is no good reason for us continuing to process it. You also have the
            right to ask us to delete or remove your personal data where you
            have successfully exercised your right to object to processing (see
            below), where we may have processed your information unlawfully or
            where we are required to erase your personal data to comply with
            local law. Note, however, that we may not always be able to comply
            with your request of erasure for specific legal reasons which will
            be notified to you, if applicable, at the time of your request.
          </li>
          <li>
            Request restriction of the processing of your personal data. This
            enables you to ask us to suspend the processing of your personal
            data in one of the following scenarios: (i) if you want us to
            establish the data’s accuracy; (ii) where our use of the data is
            unlawful but you do not want us to erase it; (iii) where you need us
            to hold the data even if we no longer require it as you need it to
            establish, exercise or defend legal claims; or (iv) you have
            objected to our use of your data but we need to verify whether we
            have overriding legitimate grounds to use it.
          </li>
          <li>
            Object to the processing of your personal data where we are relying
            on a legitimate interest (or those of a third party) as the legal
            basis for that particular use of your data (including carrying out
            profiling based on our legitimate interests). In some cases, we may
            demonstrate that we have compelling legitimate grounds to process
            your information which override your right to object.
          </li>
          <li>
            Request the transfer of your personal data to you or to a third
            party. We will provide to you, or a third party you have chosen,
            your personal data in a structured, commonly used, machine-readable
            format. Note that this right only applies to automated information
            which you initially provided consent for us to use or where we used
            the information to perform a contract with you.
          </li>
          <li>
            Object any time to the processing of your personal data for direct
            marketing purposes.
          </li>
          <li>
            Withdraw consent at any time where we are relying on consent to
            process your personal data as the legal basis for using your data.
            However, this will not affect the lawfulness of any processing
            carried out before you withdraw your consent. If you withdraw your
            consent, we may not be able to provide certain products or services
            to you. We will advise you if this is the case at the time you
            withdraw your consent.
          </li>
        </ul>
        <p className={classes.text}>
          If you wish to exercise any of the above rights, please contact us as
          set out at the end of this Privacy Policy.
        </p>
        <p className={classes.text}>
          We will try to respond to all legitimate requests within 30 days and
          will deal with requests we receive from you, in accordance with the
          provisions of Data Protection Law. Occasionally it could take us
          longer, if your request is particularly complex or you have made a
          number of requests. In this case, we will notify you and keep you
          updated.
        </p>
        <p className={classes.text}>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we could refuse to comply with your request in these
          circumstances.
        </p>
        <p className={classes.text}>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </p>
        <h2 className={classes.subHeader}>Marketing Communications</h2>
        <p className={classes.text}>
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on which products, services and offers may be of
          interest to you so that we can send you relevant marketing
          communications.
        </p>
        <p className={classes.text}>
          <strong>Direct marketing:</strong> You will receive marketing
          communications if you “opt in” to receive marketing communications
          from us when you registered on our Site or within the Services, or if
          you enquired about, or have purchased any of our goods or services and
          you have not opted out of receiving such marketing.
        </p>
        <p className={classes.text}>
          <strong>Third Party Marketing:</strong> You can ask us to stop sending
          you marketing communications at any time by logging into the Site or
          Services and unchecking the relevant boxes to adjust your marketing
          preferences or by following the “opt out” or “unsubscribe” links
          within any marketing communication sent to you.
        </p>
        <p className={classes.text}>
          <strong>Opting out of Marketing:</strong> You will receive marketing
          communications if you “opt in” to receive marketing communications
          from us when you registered on our Site or within the Services, or if
          you enquired about, or have purchased any of our goods or services and
          you have not opted out of receiving such marketing.
        </p>
        <p className={classes.text}>
          Once you “opt out” or “unsubscribe”, you will no longer receive any
          marketing communications from us. You will however still received
          service related communications that are essential for administrative
          or customer service purposes, for example relating to orders, billing,
          updates, checking that your contact details are up to date and support
          issues.
        </p>
        <p className={classes.text}>
          Please note that where we send push notifications from time to time in
          order to update you about any service updates, events and promotions
          we may be running, if you no longer wish to receive these
          communications, please disable these in the settings on your device.
        </p>
        <h2 className={classes.subHeader}>Complaints</h2>
        <p className={classes.text}>
          Our intention is to meet the highest standards when collecting and
          using personal data. For this reason, we take complaints we receive
          very seriously. We encourage users to notify us if they think that our
          collection or use of personal data is unfair, misleading or
          inappropriate. If you have any complaints about our use of your
          personal data, please contact us as set out at the end of this Privacy
          Policy or you have the right to make a complaint to your local data
          protection supervisory authority.
        </p>
        <p className={classes.text}>
          Our EU, UK and Swiss data protection representative is: <br></br>
          DataRep of The Cube, Monahan Road, Cork, T12 H1XY, Republic of
          Ireland. If you wish to raise a question please contact them, quoting
          “Node Monitoring FZCO” in the subject line.
        </p>
        <h2 className={classes.subHeader}>Age of users</h2>
        <p className={classes.text}>
          This Site and the Services are not intended for and shall not be used
          by anyone under the age of 16.
        </p>
        <h2 className={classes.subHeader}>Changes to our privacy policy</h2>
        <p className={classes.text}>
          We keep our Privacy Policy under regular review. Any changes we may
          make to our Privacy Policy in the future will be posted on this page
          and, where appropriate, notified to you by email. Please check back
          frequently to see any updates or changes to our Privacy Policy.{" "}
        </p>
        <p className={classes.text}>
          This Privacy Policy was last updated on 10 April 2024 and this version
          replaces any other Privacy Policy previously applicable from this
          date.
        </p>
        <h2 className={classes.subHeader}>Your duty to inform us of changes</h2>
        <p className={classes.text}>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during your relationship with us, for example a new address or email
          address.
        </p>
        <h2 className={classes.subHeader}>Contact</h2>
        <p className={classes.text}>
          By post: Node Monitoring FZCO of Dubai Silicon Oasis, DDP, Building
          A1, Dubai, United Arab Emirates.
        </p>
        <p className={classes.text}>
          By email:{" "}
          <a href="mailto:privacy@nodemonitoring.io">
            privacy@nodemonitoring.io
          </a>
          .
        </p>
        <p className={classes.text}>Third Party Supplier List:</p>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.th}>Third Party</th>
                <th className={classes.th}>Service</th>
                <th className={classes.th}>Privacy Policy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tdNoWidth}>
                  <strong>OVH US LLC dba OVHcloud</strong>
                </td>
                <td className={classes.tdNoWidth}>Hosting services</td>
                <td className={classes.tdNoWidth}>
                  <a href="https://us.ovhcloud.com/legal/eu-privacy-notice/">
                    https://us.ovhcloud.com/legal/eu-privacy-notice/
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tdNoWidth}>
                  <strong>Microsoft Corporation</strong>
                </td>
                <td className={classes.tdNoWidth}>Email services</td>
                <td className={classes.tdNoWidth}>
                  <a href="https://go.microsoft.com/fwlink?linkid=521839">
                    https://go.microsoft.com/fwlink?linkid=521839
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tdNoWidth}>
                  <strong>Google Inc.</strong>
                </td>
                <td className={classes.tdNoWidth}>
                  Website analytics services
                </td>
                <td className={classes.tdNoWidth}>
                  <a href="https://policies.google.com/privacy">
                    https://policies.google.com/privacy
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tdNoWidth}>
                  <strong>Stripe Inc.</strong>
                </td>
                <td className={classes.tdNoWidth}>
                  Payment processing services
                </td>
                <td className={classes.tdNoWidth}>
                  <a href="https://stripe.com/legal/data-privacy-framework">
                    https://stripe.com/legal/data-privacy-framework
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tdNoWidth}>
                  <strong>Pipedrive OU</strong>
                </td>
                <td className={classes.tdNoWidth}>CRM services</td>
                <td className={classes.tdNoWidth}>
                  <a href="https://www.pipedrive.com/en/privacy">
                    https://www.pipedrive.com/en/privacy
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tdNoWidth}>
                  <strong>Bodle Law</strong>
                </td>
                <td className={classes.tdNoWidth}>Legal services</td>
                <td className={classes.tdNoWidth}>
                  <a href="https://www.bodlelaw.com/privacy-policy">
                    https://www.bodlelaw.com/privacy-policy
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tdNoWidth}>
                  <strong>The Rocket Science Group LLC d/b/a Mailchimp</strong>
                </td>
                <td className={classes.tdNoWidth}>Email delivery services</td>
                <td className={classes.tdNoWidth}>
                  <a href="https://www.intuit.com/privacy/statement/">
                    https://www.intuit.com/privacy/statement/
                  </a>
                </td>
              </tr>
              <tr>
                <td className={classes.tdNoWidth}>
                  <strong>Slack Technologies Limited</strong>
                </td>
                <td className={classes.tdNoWidth}>Support services</td>
                <td className={classes.tdNoWidth}>
                  <a href="http://www.salesforce.com/assets/pdf/misc/privacy-shield-notice.pdf">
                    http://www.salesforce.com/assets/pdf/misc/privacy-shield-notice.pdf
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
