import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  footerSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1.9vh 1.8vw 1vh;",
    background: "#212121",

    [`@media screen and (max-width: 700px)`]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "30px",
      padding: "20px 50px",
    },
  },
  logo: {
    cursor: "pointer",
    padding: "0.5vh 0px",

    [`@media screen and (max-width: 700px)`]: {
      display: "none",
    },
  },
  logoMobile: {
    cursor: "pointer",
    padding: "5px 0px",
    height: "32px",
  },
  footerLinks: {
    display: "flex",
    alignItems: "center",
    gap: "5vw",
    width: "25%",
    [`@media screen and (max-width: 1300px)`]: {
      gap: "2vw",
    },
    [`@media screen and (max-width: 700px)`]: {
      width: "auto",
      gap: "5vw",
    },
  },
  footerLink: {
    ...theme.typography.body1,
    color: "white",
    textDecoration: "none",
    width: "25%",
    "&:hover": {
      textDecoration: "underline",
    },
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "12px",
    },
  },
  footerCenter: {
    ...theme.typography.body1,
    display: "flex",
    alignItems: "center",
    color: "white",
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "12px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      fontSize: "12px",
    },
    [`@media screen and (max-width: 700px)`]: {
      display: "none",
    },
  },
  footerCenterMobile: {
    fontSize: "10px",
    display: "flex",
    alignItems: "end",
    color: "white",
    [`@media screen and (min-width: 700px)`]: {
      display: "none",
    },
  },
  logoContainer: {
    width: "25%",
  },
}));

interface FooterSectionProps {}

export const FooterSection: React.FC<FooterSectionProps> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.footerSection}>
      <div className={classes.logoContainer}>
        <img
          className={classes.logo}
          src="/images/footer-logo.png"
          alt="logo"
          onClick={() => {
            window.scrollTo({ top: 0 });
            window.location.reload();
          }}
        ></img>
      </div>
      <div className={classes.footerCenter}>
        © 2024 Node Monitoring Service (NMS)
      </div>
      <div className={classes.footerLinks}>
        <a href="/terms" target="_blank" className={classes.footerLink}>
          Terms
        </a>
        <a href="/privacy" target="_blank" className={classes.footerLink}>
          Privacy
        </a>
        <a
          href="mailto:support@nodemonitoring.io"
          className={classes.footerLink}
          rel="noreferrer"
        >
          Support
        </a>
      </div>
      <div className={classes.footerCenterMobile}>
        © 2024 Node Monitoring Service (NMS)
      </div>
    </div>
  );
};
